import React from 'react';
import DocumentTitle from '../../components/DocumentTitle';

export default function StartContainer({children, title}){
    return   <div className={"bg-light"}>
        {
            title && <DocumentTitle title={title}/>
        }
        <div className={"container-fluid"}>
            <div className={"row justify-content-center min-vh-100"} style={{paddingTop:'80px'}}>
                <div className={"col-md-6 col-lg-4"}>
                    {children}
                </div>
            </div>
        </div>
    </div>
}
