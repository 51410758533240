import React, {Component} from 'react';
import CopyToClipboard from "react-copy-to-clipboard";
import PropTypes from 'prop-types';

class CopyButton extends Component {
        state = {
            copied:false,
        }
    onCopy = ()=>{
        this.setState({copied:true});
        setTimeout(()=>{
            this.setState({copied:false})
        }, 1000);
    }
    render() {
        return (
            <CopyToClipboard text={this.props.text} onCopy={this.onCopy}>
                <button type={"button"}
                        disabled={this.state.copied}
                        className={this.props.className}>
                    {this.state.copied ? 'Copied' : this.props.copyBtnText || 'Copy'}</button>
            </CopyToClipboard>
        );
    }
}

CopyButton.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
    copyBtnText: PropTypes.string

}

export default CopyButton;
