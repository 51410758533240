import React, {useEffect, useState} from 'react';
import Youtube from '../../../components/video/Youtube';
import BackButton from '../../../components/BackButton';
import FormGroup from '../../../components/form/FormGroup';
import {connect} from 'react-redux';
import settingsDispatchToProps
    from '../../settings/premiumextras/settingsDispatchToProps';
import settingsStateToProps from '../../settings/settingsStateToProps';
import Button from '../../../components/form/Button';
import {useNavigate} from 'react-router-dom';

function SquarespaceApiKey({sq_api_key, updateSettings}) {
    const navigate = useNavigate()
    const [apiKey, setApiKey] = useState('')
    const [loading, setLoading] = useState(false)
    useEffect(()=>{
        setApiKey(sq_api_key)
    },[sq_api_key])

    function onSubmit(e){
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
        setLoading(true)
        updateSettings({
            sq_api_key: apiKey
        }).then(()=>{
            setLoading(false)
            navigate(-1)
        })
    }

    return (
        <div className={"bg-light min-vh-100"}>
            <div style={{paddingTop:100}}/>

            <div className="container">
                <BackButton/>
                <div className="card">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card-body">
                            <h3>Squarespace API Key</h3>
                            <ol>
                                <li>Open Squarespace Site Admin</li>
                                <li>Click <code>Settings</code> -> <code>Advanced</code> -> <code>Developer API Keys</code></li>
                                <li>Click <kbd>GENERATE KEY</kbd> button</li>
                                <li>In the popup, set <mark>Key Name</mark> as <strong>Goaffpro</strong></li>
                                <li>Then TICK <strong>Products</strong>, Inventory, <strong>Orders</strong>, Transactions, Profiles permission. Keep the permission level to READ ONLY</li>
                                <li>Click <kbd>GENERATE KEY</kbd> in the popup</li>
                                <li>Copy the generated API KEY and paste it below</li>
                            </ol>
                            <form onSubmit={onSubmit}>
                            <FormGroup label={"API KEY"}>
                                <input type="text"
                                       value={apiKey}
                                       onChange={(e)=>setApiKey(e.target.value)}
                                       className="form-control"/>
                            </FormGroup>
                                <Button loading={loading} disabled={!apiKey || sq_api_key === apiKey} className={"btn btn-primary"} onClick={onSubmit}>Submit</Button>
                            </form>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <Youtube videoId={"85Ihy5Szxn4"} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}


export default connect(settingsStateToProps(['sq_api_key']), settingsDispatchToProps)(SquarespaceApiKey);
