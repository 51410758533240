import React, {useCallback, useEffect, useState} from 'react';
import * as localStorage from '../../utils/storage';
import AdminStoreNameBanner from '../../components/AdminStoreNameBanner';
import {useNavigate} from 'react-router-dom';
import BackButton from '../../components/BackButton';

export default function LogoutPage() {
  const [isLogoutConfirmed, setIsLogoutConfirmed] = useState();

  const logout = useCallback(() => {
    setIsLogoutConfirmed(true);
    localStorage.removeItem('access_token');
    localStorage.removeItem('manager_access_token');
  }, []);

  function onComplete() {
    window.location.assign('https://goaffpro.com');
  }

  const navigate = useNavigate();
  return (
      <div className="bg-light min-vh-100">
        <AdminStoreNameBanner/>
        <div className="container">
          <div className="row mt-4">
            <div className="col-md-4"/>
            <div className="col-md-4">
              <BackButton/>
              <div className="card mt-2">
                <div className="card-body">
                  {
                    isLogoutConfirmed ? <>
                      <div className="text-center">
                        <p>You have been successfully logged out</p>
                        <p>Taking you to <a
                            href={'/auth/login'}>goaffpro.com</a> in <strong><Countdown
                            onComplete={onComplete}
                            startSeconds={5}/></strong>
                        </p>
                      </div>
                    </> : <>
                      <p>
                        Are you sure you want to logout ?
                      </p>
                      <div className="space-between">
                        <button onClick={logout}
                                className="btn btn-danger">Logout
                        </button>
                        <button onClick={() => navigate(-1)}
                                className="btn btn-light">Cancel
                        </button>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

  );
}

function Countdown({startSeconds, onComplete}) {
  const [seconds, setSeconds] = useState();
  useEffect(() => {
    setSeconds(startSeconds);
  }, [startSeconds]);
  useEffect(() => {
    const x = setTimeout(() => {
      if (seconds === 1) {
        onComplete();
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => clearTimeout(x);
  }, [seconds]);
  return seconds;
}
