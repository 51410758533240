import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  actionMergeSetting,
  loadSettings,
} from '../redux/settings/settingsAction';
import {connect} from 'react-redux';
import {getHttpClient} from '../api';
import Spinner from '../components/Spinner';
import * as localStorage from '../utils/storage';

function MasterPage() {
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeStore, setActiveStore] = useState(null);
  const [initialToken, setInitialToken] = useState(false);
  const [multiSiteToken, setMultiSiteToken] = useState(null);
  const frame = useRef(null);
  useEffect(() => {
    setLoading(true);
    if (localStorage.getItem('manager_access_token')) {
      getHttpClient().post('/manager/get_manager_stores', {
        access_token: localStorage.getItem('manager_access_token'),
      }).then(({data}) => {
        setMultiSiteToken(data.multiSiteToken);
        setStores(data.sites);
        setLoading(false);
        setActiveStore(data.sites[0].site_id);
        setInitialToken(data.sites[0].access_token);
      }).catch((e) => {
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (!activeStore) return;
    const access_token = stores.find(
        (item) => item.site_id === activeStore).access_token;
    const sites = stores.map(({name}) => name);

    if (access_token) {
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('sites', JSON.stringify(sites));
      localStorage.setItem('multi_site_access_token', multiSiteToken);
    }
    if (frame) {
      frame.current.contentWindow.postMessage({
        access_token,
        sites,
        multi_site_access_token: multiSiteToken,
      }, '*');
    }
  }, [activeStore]);

  if (!activeStore) return <p className={'text-center mt-4'}><Spinner/></p>;

  return (
      <div className={'row no-gutters'}
           style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0}}>
        <div className="col-sm-2">
          <div className="m-2">
            <h4>Stores</h4>
          </div>
          <ul className="list-group list-group-flush">
            {
              stores.map(({site_id, access_token, store_name, website}) => {
                return <StoreItem
                    active={site_id === activeStore}
                    store_name={store_name}
                    onClick={(e) => setActiveStore(site_id)}
                    website={website}
                />;
              })
            }
          </ul>
          <LogoutButton/>
        </div>
        <div className="col-sm-10">
          <iframe ref={frame}
                  style={{width: '100%', border: 0, height: '100%'}}
                  src={'/t/' + initialToken}/>
        </div>
      </div>
  );
}

function LogoutButton() {
  const logout = useCallback(() => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('manager_access_token');
    localStorage.removeItem('multi_site_access_token');
    window.location = '/master';
  }, []);
  return <div className="my-4 text-center">
    <button onClick={logout} className={'btn btn-link text-danger'}>Logout
    </button>
  </div>;
}

function StoreItem({active, onClick, store_name, website}) {
  return <button className={`list-group-item list-group-item-action ${active ?
      'active' :
      ''}`}
                 onClick={onClick}>
    <div>{store_name}</div>
    <small>{website}</small>
  </button>;
}

function mapStateToProps(state) {
  return {
    master_token: state.settings.master_token,
    loading: state.settings.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadSettings: () => dispatch(loadSettings()),
    mergeSettings: (change) => dispatch(actionMergeSetting(change)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterPage);
