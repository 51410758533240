import Cookies from 'js-cookie';
import {setAccessToken} from './access_token_handler';

export function setItem(key, value) {
  if (key === 'access_token') {
    setAccessToken(value);
  }
  localStorage.setItem(key, value);
  Cookies.set(key, value);
}

export function getItem(key) {
  return localStorage.getItem(key) || Cookies.get(key);
}

export function removeItem(key) {
  if (key === 'access_token') {
    setAccessToken(null);
  }
  localStorage.removeItem(key);
  Cookies.remove(key);
}
