import {combineReducers} from 'redux';
import settingsReducer from './settings/settingsReducer';
import affiliateReducer from "./affiliate/affiliateReducer";
import sectionReducer from './pagebuilder/sectionReducer';
import landingReducerRoot from "../pages/landingbuilder/redux/landingReducerRoot";
import customerMapReducer from "../pages/customers-affiliate-map/redux/customerMapReducer";
import signupPagesReducer
    from "../pages/customize/tabs/signup-page-editor/extra-signup-pages/redux/signupPagesReducer";
import scriptsReducer from "../pages/scripts/redux/scriptsReducer";
import emailEditorReducer from "../pages/email-editor/redux/emailEditorReducer";
import paymentPlansReducer from "../pages/affiliate-membership/redux/paymentPlansReducer";
import groupsReducer from "../pages/partnergroups/redux/groupsReducer";
export default combineReducers({
  settings:settingsReducer,
  currentAffiliate: affiliateReducer,
  pageSections: sectionReducer,
  landingComponents: landingReducerRoot,
  customerMap: customerMapReducer,
  signupPages: signupPagesReducer,
  scripts: scriptsReducer,
  emailBuilder:emailEditorReducer,
  paymentPlans: paymentPlansReducer,
  groups: groupsReducer,
});
