import React, {Component} from 'react';
import SignatureInput from './SignatureInput';
import CountrySelector from '../formbuilder/CountrySelector';
import RadioGroup from './RadioGroup';
import SimpleFileUpload from './SimpleFileUpload';
import Checkbox from './Checkbox';
import {FormattedMessage} from 'react-intl';
import CurrencySelector from './CurrencySelector';
import Label from './Label';
import mydayjs from '../../utils/mydayjs';
import ReactSelect from '../react-select/ReactSelect';

class RenderFormInput extends Component {

  keys = [
    'isRequired',
    'label',
    'value',
    'placeholder',
    'id',
    'prepend',
    'type',
    'size',
    'options',
    'onChange',
    'info'];

  shouldComponentUpdate(nextProps) {
    return !!this.keys.find((key) => {
      return this.props[key] !== nextProps[key];
    });
  }

  onChange = (id) => e => {
    this.props.onChange(id)(e);
  };

  render() {
    const {
      isRequired,
      label,
      value,
      placeholder,
      info,
      id,
      prepend,
      append,
      type,
      size,
      options,
    } = this.props;
    const onChange = this.onChange(id);
    let inputCmp = null;
    switch (type) {
      case 'signature':
        inputCmp = <SignatureInput onChange={onChange} value={value}/>;
        break;
      case 'textarea':
        inputCmp = (<textarea id={id} name={fieldNameMapper[id] || label}
                              value={value} onInput={onChange}
                              className={'form-control'}
        />);
        break;
      case 'country':
        inputCmp = (
            <CountrySelector value={value}
                             onChange={(value) => onChange({target: {value}})}/>
        );
        break;
      case 'select':
        const opts = typeof options === 'string' ?
            options.split('\n').
                map((item) => item.trim()).
                filter((i) => i).
                map((item) => <option value={item}>{item}</option>)
            :
            options.map(
                (item) => <option value={item.value}>{item.label}</option>);
        inputCmp = (
            options &&
            <select className={'form-control'} value={value} id={id}
                    name={label} onChange={onChange}>
              <option/>
              {opts}
            </select>
        );
        break;
      case 'multiple-select':
        let va = value && Array.isArray(value) && value.map((val) => {
          return {value: val, label: val};
        });
        if (value && typeof value === 'string') {
          va = value.split(',').map((i) => {
            return {value: i, label: i};
          });
        }
        inputCmp = (
            options && <ReactSelect isMulti options={options.split('\n').
                map((item) => item.trim()).
                filter((i) => i).
                map((item) => {
                  return {
                    label: item,
                    value: item,
                  };
                })} value={va} onSelected={(val) => onChange(
                {target: {value: val.map(({value}) => value)}})}/>
        );
        break;
      case 'radio':
        inputCmp = (options &&
            <RadioGroup options={options.split('\n').
                map((item) => item.trim()).
                filter((i) => i)}
                        onChange={onChange} checked={value} name={label}
            />
        );
        break;
      case 'fileupload':
        inputCmp = <SimpleFileUpload value={value} imgWidth={200}
                                     onChange={(data) => onChange(
                                         {target: {value: data}})}/>;
        break;
      case 'checkbox':
        inputCmp =
            <div className={`form-group col-${Math.round(12 / (size || 1))}`}>
              <Checkbox
                  label={<FormattedMessage id={label} defaultMessage={label}/>}
                  checked={value} value={label} onChange={(e) => onChange(
                  {target: {value: e.target.checked}})}/>
            </div>;
        break;
      case 'currency':
        inputCmp = <CurrencySelector onChange={onChange} value={value}/>;
        break;
      case 'date':
        const val = new Date(value).toString() !== 'Invalid Date' ?
            mydayjs(value).format('YYYY-MM-DD') :
            value;
        inputCmp = (<input placeholder={placeholder}
                           type={type || inputTypeMapper[id] || 'text'} id={id}
                           name={fieldNameMapper[id] || label}
                           value={val} onChange={onChange}
                           autoComplete={autofillFieldMapper[id] || 'on'}
                           className={'form-control'}
        />);
        break;
      default:
        inputCmp = (<input placeholder={placeholder}
                           type={type || inputTypeMapper[id] || 'text'} id={id}
                           name={fieldNameMapper[id] || label}
                           value={value} onChange={onChange}
                           autoComplete={autofillFieldMapper[id] || 'on'}
                           className={'form-control'}
        />);

    }
    return (<div className={`form-group col-${12 / (size || 1)}`}>
      {label && <Label required={isRequired} title={<FormattedMessage id={label}
                                                                      defaultMessage={label}/>}/>}
      {
        (prepend || append) ?
            <div className="input-group">
              {
                  prepend &&
                  <div className="input-group-prepend">
                    <span className="input-group-text">{prepend}</span>
                  </div>
              }
              {
                inputCmp
              }
              {
                  append &&
                  <div className="input-group-append">
                    <span className="input-group-text">{append}</span>
                  </div>
              }
            </div> : inputCmp
      }
      {
          info && <small className={'text-muted'}>{info}</small>
      }
    </div>);
  }
}

const autofillFieldMapper = {
  name: 'name',
  fname: 'given-name',
  phone: 'tel',
  address: 'address',
  address_1: 'address-line1',
  address_2: 'address-line2',
  lname: 'family-name',
  email: 'email',
  password: 'new-password',
  state: 'address-level1',
  city: 'address-level2',
  zip: 'postal-code',
  country: 'country',
};

const inputTypeMapper = {
  email: 'email',
  phone: 'tel',
  paypal: 'email',
};
const fieldNameMapper = {
  name: 'name',
  fname: 'fname',
  lname: 'lname',
  phone: 'phone',
  email: 'email',
  address_1: 'address',
  address: 'address',
  city: 'city',
  state: 'state',
  country: 'country',
  zip: 'zip',
};

export default RenderFormInput;
