import axios from "axios";
import {customAlphabet} from 'nanoid';
import {saveAs} from 'file-saver';

const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyz', 10)

export function getCurrentQueryObject(){
    const map = {};
    if(window.location.search) {
        window.location.search.substr(1).split("&").map((item) => {
            return item.split("=");
        }).forEach((x) => {
            if (x[1]) {
                map[x[0]] = decodeURIComponent(x[1].trim());
            }
        })
    }
    return map;
}


export function getNames ({ name, fname, lname }) {
    try {
        let n = name || `${fname ? fname + ' ' : ''}${lname || ''}` || ''
        const parts = n.split(' ')
        let f = fname || (name && parts[0]) || ''
        let l = lname || (name && parts.slice(1).join(' ')) || ''
        return {
            name: n,
            fname: f,
            lname: l
        }
    } catch (e) {
        console.error(e);
    }
    return { name, fname, lname }
}

export function buildUrl(base, queryObject){
    const keys = Object.keys(queryObject);
    const str = keys.map((key)=>{
        return `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`
    });
    return `${base}?${str.join("&")}`;
}

export function shopifyRedirect(url){
    if(window.self === window.top) {
        window.location = url;
    }else if(window.top){
        window.top.location.href = url
    }
}


export function isValidEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function monthAgo(){
    var d = new Date();
    d.setMonth(d.getMonth() - 1);
    return d;
}

export function randomString(){
    return nanoid()
}

export function getReviewLink(integration = "shopify"){
    switch (integration) {
        case "shopify":
            return "https://apps.shopify.com/goaffpro#modal-show=ReviewListingModal&utm_campaign=installed&utm_content=contextual&utm_medium=shopify&utm_source=admin"
        case "wordpress":
            return 'https://wordpress.org/plugins/goaffpro'
        case "wix":
            return "https://www.wix.com/app-market/add-review/goaffpro-affiliate-marketing"
        case "bigcommerce":
            return "https://www.bigcommerce.com/apps/goaffpro/"
        default:
            return null;
    }
}
const Big = require('big.js');
export function amountAfterWitholdingTax(amount, withholding_tax, withholding_tax_type){
    if(withholding_tax_type === "paid_by_affiliate"){
        return  Big(withholding_tax || 0).div(100).plus(1).times(amount || 0).times(1).toFixed(2)
    }else{
        return Big(withholding_tax || 0).div(100).minus(1).times(amount || 0).times(-1).toFixed(2)
    }
}

/**
 * @param string
 * @param replacerObject
 */
export function replaceStrings (string, replacerObject) {
    // cleanup object
    if (replacerObject) {
        Object.keys(replacerObject).forEach((item) => {
            if (replacerObject[item] === undefined) {
                replacerObject[item] = ''
            }
        })
    }

    if (replacerObject && string) {
        let str = string
        Object.keys(replacerObject).forEach((item) => str = str.replace(new RegExp(`{${item}}`, 'g'), replacerObject[item]))
        return str
    }
    return string
}


export function reorderArray(list, startIndex, endIndex){
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
}

export function pad(num, paddingLength, paddingWith = "0"){
    return (Array.from({length:paddingLength}).map((item)=>paddingWith).join("")+num).substr(-paddingLength)
}

export function removeUndefined(object){
    Object.keys(object).forEach((item)=>{
        if(object[item] === undefined){
            delete object[item];
        }
    })
    return object;
}

const getExt = (filename = '')=>{
    const parts = filename.split(".")
    return parts && parts.length > 0 ? parts[parts.length-1].toLowerCase() : undefined;
}
const imageExtensions = ['gif','jpeg','jpg','png', 'bmp','webp'];
const videoExtensions = ['mp4','mov','avi','3gp','mkv'];
export const isImage = (url)=>{
    console.log('checking is image', url)
    const ext = getExt(url);
    return ext && (imageExtensions.indexOf(ext) > -1)
}


export function testShopifyStorefrontAccessToken(website, storefront_access_token){
    const client =  axios.create({
        baseURL:`https://${website}/api/2022-10/`,
        headers:{
            'X-Shopify-Storefront-Access-Token': storefront_access_token
        }
    })
    const query = `
        shop{
            name
        }
    `
    return client.post('/graphql.json', {
        query
    }).then(({data})=>{
        return true;
    }).catch((e)=>{
        return false;
    })
}

export const reorder = (list, startIndex, endIndex) => {
    if(startIndex!==undefined && endIndex !== undefined) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    }else{
        return list;
    }
};
export function trimPostData(postData = {}){
    const keys = Object.keys(postData);
    const toPost = {};
    for(let item of keys){
        if(postData[item] !== undefined) {
            toPost[item] = typeof postData[item] === "string" ? postData[item].trim() :  postData[item]
        }
    }
    return toPost
}
interface HeaderItem{
    title:string;
    id: string;
}
export function objectToCSVDownload(headers: Array<HeaderItem>, data: Array<Record<string, string|number>>, filename: string){
    const headings = headers.map(({title})=>`"${title || ''}"`).join(",");
    const body = data.map((i)=>{
        return headers.map(({id})=>`"${i[id] || ''}"`).join(",")
    }).join("\r\n");
    const blob = new Blob([headings,"\r\n", body], {type: "text/csv;charset=utf-8"});
    saveAs(blob, filename);
}

export function parseJsonIfRequired(x: string | any, defaultValueIfUndefined?:any){
    return (typeof x === "string" ? JSON.parse(x) : x) || defaultValueIfUndefined
}

export function isAffiliateManagedByGoaffpro(affiliate){
    return affiliate.utm_source === "managed by goaffpro"
}
export function updateImageUrl(url: string, {width, height}:{width?: number, height?: number}){
    const u  = new URL(url.replace('creatives.goaffpro.com','media.goaffpro.com'))
    u.searchParams.append('auto_optimize', 'medium')
    if(width) {
        u.searchParams.append('width', String(width))
    }
    if(height) {
        u.searchParams.append('height', String(height))
    }
    return u.href;
}
