import axios from 'axios';
import * as localStorage from './utils/storage';
import {getAccessToken} from './utils/access_token_handler';
import {isShopifyEmbedTestMode} from './utils/shopify-embed-testing';
// export const baseURL = 'http://localhost:8080';
export const baseURL = process.env.NODE_ENV === 'development' ?
    'https://api-server-3.goaffpro.com' :
    'https://api.goaffpro.com';

export const appURL = process.env.NODE_ENV === 'development' ?
    'http://localhost:3000' :
    'https://app.goaffpro.com';

export function getHttpClient(config = {}) {
  const token = getAccessToken();
  const headers = {};
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }
  if (config && config.useMultiSiteToken &&
      sessionStorage.getItem('multi_site_access_token')) {
    headers['Authorization'] = `Bearer ${sessionStorage.getItem(
        'multi_site_access_token')}`;
  }

  return axios.create({
    baseURL,
    // timeout: 30000,
    headers,
    withCredentials: true,
    ...config,
  });
}

export function login({email, password, site_domain}) {
  return getHttpClient().post('/login', {
    email, password, site_domain,
  });
}

export function loginSiteOwner({email, password}) {
  return getHttpClient().post('/users/login', {
    email, password,
  }).then(({data}) => {
    if (data.access_token) {
      localStorage.setItem('access_token', data.access_token);
    }
    return data;
  });
}


export async function uploadImage(file) {
  // get presigned post
  return new Promise(async (resolve, reject) => {
    const {url, fields} = await getHttpClient().
        post('/v1/admin/creatives/preSignedUrl', {
          name: file.name,
        }).
        then(({data}) => data);
    const formData = new FormData();

    Object.keys(fields).forEach((key) => {
      formData.append(key, fields[key]);
    });
    formData.append('content-type', file.type);
    formData.append('file', file);
    await axios.post(url, formData);
    const uri = `https://media.goaffpro.com/${fields.key}`;
    // once uploaded, get image dimensions
    const img = new Image();
    img.onload = function() {
      console.log('loaded', this.width, this.height);
      resolve({
        width: this.width,
        height: this.height,
        url: uri,
        size: file.size,
        type: file.type,
      });
    };
    img.onerror = reject;
    console.log(uri);
    img.src = uri;
  });
}


export const swrFetcher = url => getHttpClient().get(url).then(res => res.data);
