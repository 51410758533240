import React, {useEffect} from 'react';
import {getCurrentQueryObject} from '../../utils/func';
import {useNavigate, useParams} from 'react-router-dom';
import {setAccessToken} from '../../utils/access_token_handler';

function IframeCookieFix() {
  const params = useParams();
  const navigate = useNavigate()
  useEffect(() => {
    if(params.access_token){
        if(params.access_token === "undefined"){
          navigate("/",{
            replace:true
          })
        }else{
          setAccessToken(params.access_token)
          const {then} = getCurrentQueryObject() || {then: ''};
          if (then && then.startsWith('/')) {
            navigate(then, {
              replace: true
            })
          } else {
            window.location.href = window.location.href.replace(/\/t\/.+/, '');
          }
        }
      }
  }, [params.access_token]);
  return <p className="text-center py-4">Just a moment...</p>;
}

export default IframeCookieFix;
