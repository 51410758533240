import {ACTIONS} from "./sectionActions";

const defaultSections = {
  "default_navigation": {
    key:'default_navigation',
    component:'TopNavBar',
    props:{
      visible:true,
      navbar_bg:'#f8f9fa',
      logo_align:'left',
    }
  },
  "default_banner":{
    key:'default_banner',
    component:'HeroComponent',
    props:{
      visible:true,
      hero_supertext:"Profit from our experience"
    }
  },
  "default_cards":{
    key:'default_cards',
    component:'LandingThreeCard',
    props:{
      visible:true,
      cards:[
        {
          title:'Join',
          img:'https://static.goaffpro.com/assets/003-open.svg',
          description:'It\'s free and easy to join.\n' +
              '\n' +
              'Get up and running today.\n' +
              '\n' +
              '\n' +
              '[Join Now](/create-account)\n'
        },
        {
          title:'Promote',
          img:'https://static.goaffpro.com/assets/002-megaphone.svg',
          description:'Choose from our products to advertise to your customers.\n' +
              '\n' +
              'Whether you are a large marketplace, content site, social media influencer or blogger, we have simple linking tools to meet your advertising needs and help you monetize\n' +
              '\n' +
              '\n' +
              '[Linking Tools](/program-details/tools)'
        },
        {
          title:'Earn',
          img:'https://static.goaffpro.com/assets/001-change.svg',
          description:'Get up to {commission_value} in commissions on successful referrals.\n' +
              'Earn commissions from all qualifying purchases, not just the products you advertised. Plus, our competitive conversion rates help you maximize your earnings.\n' +
              '\n' +
              '\n' +
              '[Product Commissions](/program-details/commissions)'
        }
      ]
    }
  },
  "default_bottom_button":{
    key:'default_bottom_button',
    component:'JoinNowBottomButton',
    props:{
      visible:true,
    }
  },
  "default_footer":{
    key:'default_footer',
    component:'LandingFooter',
    props:{
      visible:true,
      blocks:[
        {
          title:'Learn',
          description:'{link_commissions}\n{link_tools}\n{link_reporting}',
        },
        {
          title:'Customer Support',
          description:'{link_contact}',
        },

        {
          title:'Legal',
          description:'{link_terms}\n{link_privacy}',
        },
        {
          title:'',
          description:'©{year}, {website}\n{language_picker}',
        },
      ]
    }
  }
};

export default (state = defaultSections, action)=>{
  switch (action.type) {
    case ACTIONS.update:
      return {...state, [action.section_id]: {...state[action.section_id], ...action.change}}
    case ACTIONS.del:
      return {...state, [action.section_id]: undefined}
    case ACTIONS.add:
      return {...state, [action.section_id]: action.data}
    case ACTIONS.set:
      return {...defaultSections, ...action.data}
    default:
      return state;
  }
}

