const squareupConfig = {
    sandbox:{
        client_id:'sandbox-sq0idb-6fON8y53Yu-hzywnDiP-UQ',
        redirect_uri:'http://localhost:3000/square/callback',
        baseURL:'https://connect.squareupsandbox.com/'
    },
    live:{
        client_id:'sq0idp-WIkDXqBSVUzYaMvX4WUxIA',
        redirect_uri:'https://app.goaffpro.com/square/callback',
        baseURL:'https://connect.squareup.com/'
    }
}
const scopes = [
    "ONLINE_STORE_SNIPPETS_WRITE",
    "ONLINE_STORE_SNIPPETS_READ",
    "ONLINE_STORE_SITE_READ",
    "GIFTCARDS_READ",
    "GIFTCARDS_WRITE",
    "SUBSCRIPTIONS_READ",
    "ORDERS_READ",
    "CUSTOMERS_READ",
    "CUSTOMERS_WRITE",
    "ITEMS_READ",
    "MERCHANT_PROFILE_READ",
]


export function getSquareConnectUrl(sandbox){
    const {client_id, redirect_uri, baseURL} = sandbox ? squareupConfig.sandbox : squareupConfig.live;
    const u = new URL( "/oauth2/authorize", baseURL);
    u.searchParams.append('client_id', client_id)
//    u.searchParams.append('redirect_uri', redirect_uri);
    u.searchParams.append('scope',scopes.join(" "))
    return u.href;
}
