import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import Modal from './Modal';
type DispatchOpts = {
     state: ConfirmModalOpts | null;
     type?: string;
}
type Dispatch = (opts: DispatchOpts)=>void

type ContextType = {
  state:ConfirmModalOpts;
  dispatch:Dispatch
}
export const ConfirmContext = React.createContext<ContextType | undefined>(undefined);

export function ConfirmModal(props) {
  const opts = useContext(ConfirmContext);
  const {state, dispatch} = opts || {}
  const onOk = useCallback(() => {
    if (state && state.onConfirm) state.onConfirm();
    if(dispatch) {
      dispatch({state: null});
    }
  }, [state, dispatch]);
  const onCancel = useCallback(() => {
    if(dispatch) {
      return dispatch({state: null});
    }
  }, []);
  return (
      <Modal visible={!!(state && state.message)} title={'Confirm action ?'}
             onOk={onOk}
             fade={true}
             okText={'Confirm'}
             onCancel={onCancel}>
        {state && state.message}
      </Modal>
  );
}

interface ConfirmModalOpts {message: string, onConfirm:()=>void}
export function useConfirm() {
  const opts = useContext(ConfirmContext);
  const [options, setOptions] = useState<ConfirmModalOpts>();
  const {dispatch} = opts || {}
  useEffect(() => {
    if(options && dispatch) {
      dispatch({
        type: 'update-confirm',
        state: options,
      });
    }
  }, [options]);

  return setOptions;
}

export function ConfirmationProvider({children}) {
  const [state, dispatch] = useReducer((state, action) => {
    return action.state;
  }, {});
  return <ConfirmContext.Provider value={{state, dispatch}}>
    {children}
    <ConfirmModal/>
  </ConfirmContext.Provider>;

}
