import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import RealProgressBar from "../RealProgressBar";
import {isImage} from "../../utils/func";
import {getHttpClient} from "../../api";

const uploadingStates = {
    default:0,
    uploading:1,
    uploaded:2,
    cancelled:0,
    error:-1,
}
function SimpleFileUpload({value, onChange, accept, upload_endpoint}){
    const [imageUploadPreview, setImageUploadPreview] = useState(null)
    const [data, setData]  = useState({
        location:undefined,
        width:undefined,
        height:undefined,
        originalname:undefined,
        uri:undefined,
    })
    const [uploadState, setUploadState] = useState({
        uploadingState:uploadingStates.default,
        uploadProgress: 0
    })
    useEffect(()=>{
        setData(value)
    }, [value])

    function onFileChange(e){
        var reader = new FileReader();
        reader.onload = (e)=>{
            setImageUploadPreview(e.target.result);
        };
        reader.readAsDataURL(e.target.files[0]);
        const form = new FormData();
        form.append("upload", e.target.files[0]);
        setUploadState({
            ...uploadState,
            uploadingState:uploadingStates.uploading
        })
        getHttpClient().post(upload_endpoint, form, {
            onUploadProgress: ProgressEvent => {
                setUploadState({
                    uploadProgress: Math.round(((ProgressEvent.loaded * 100) / ProgressEvent.total)),
                    uploadingState:uploadingStates.uploading
                })
            },
        }).then(({data})=>{
            setUploadState({
                uploadProgress: 100,
                uploadingState:uploadingStates.uploaded
            })
            setData(data);
            onChange(data)
            }).catch((e)=>{
            setUploadState({
                uploadProgress: -1,
                uploadingState:uploadingStates.error
            })
        })

    }
    function onDelete(){
        if(onChange){
            onChange({})
        }
        setData({});
        setUploadState({})
    }
    const {location, url, width, height, originalname} = data || {};
    const {uploadingState, uploadProgress} = uploadState;
    if(uploadingState === uploadingStates.uploading){
        return <RenderUploading uploadProgress={uploadProgress} />
    }else if(location || url){
        return <RenderUploaded url={location} originalname={originalname} onDelete={onDelete} imgWidth={width} imgHeight={height}/>
    }else{
        return <input accept={accept} type={"file"} className={"form-control-file"} onChange={onFileChange} />
    }
}


function RenderUploaded({url, onDelete, imgWidth, imgHeight, originalname}){
    return <div>
        <a title={"Open in a new tab"} target={"_blank"} href={url}>
            {
                isImage(url) ?
                    <img src={url} height={imgHeight} style={{maxHeight:200, width: imgHeight !== 'auto' ? 'auto' : imgWidth}}
                         alt={"Uploaded file"} className={"bg-light"}/>
                    : originalname
            }
        </a>
        <br/>
        <button type={"button"} className={"btn btn-link text-danger"} onClick={onDelete}>Delete</button>
    </div>
}

function RenderUploading({imageDataUri, uploadProgress}){
    return <div style={{width:'200px'}} className={"text-center"}>
        <small className={"d-block text-muted"}>Uploading</small>
        <RealProgressBar progress={uploadProgress} showProgressLabel/>
    </div>

}

SimpleFileUpload.propTypes = {
    onChange: PropTypes.func.isRequired,
    location:PropTypes.string,
    upload_endpoint: PropTypes.string,
    width: PropTypes.any,
    height: PropTypes.any
}
SimpleFileUpload.defaultProps = {
    upload_endpoint: '/sites/upload',
    width:200,
    height:'auto'
}

export default SimpleFileUpload;
