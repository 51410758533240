import {getHttpClient} from '../../api';
import {getAccessToken} from '../../utils/access_token_handler';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';


export const ACTIONS = {
    merge:'update-settings',
    set: 'set-settings'
};
export const actionMergeSetting = (data)=>{
    return {
        type:ACTIONS.merge,
        data
    }
};



export const updateSetting = (change)=>{
  return (dispatch, getState)=>{
      const original = getState().settings;
      dispatch(actionMergeSetting({saving:true}));
      dispatch(actionMergeSetting(change));
    return getHttpClient().post('/sites/settings',{
            change
        }).then(({data})=>{
            //const {setting_key, setting_value} = data;
            //dispatch(actionMergeSetting({[setting_key]: setting_value}))
            dispatch(actionMergeSetting({saving:false}));
            return data;
        }).catch((e)=>{
            const originalObject = {};
            Object.keys(change).forEach((key)=>{
                originalObject[key] = original[key];
            });
            dispatch(actionMergeSetting(originalObject));
            dispatch(actionMergeSetting({saving:false}));
        })
  }
};


export const updatePortalConfig = (change)=>{
    return (dispatch, getState)=>{
        const oldConfig = getState().settings.affiliate_portal_config;
        const oObj = oldConfig ? JSON.parse(oldConfig) : {}
        const newConfig = JSON.stringify({
            ...oObj,
            ...change
        })
        dispatch(actionMergeSetting({saving:true}));
        dispatch(actionMergeSetting({
            affiliate_portal_config: newConfig
        }));
        return getHttpClient().post('/sites/settings',{
            change:{
                affiliate_portal_config: newConfig
            }
        }).then(({data})=>{
            //const {setting_key, setting_value} = data;
            //dispatch(actionMergeSetting({[setting_key]: setting_value}))
            dispatch(actionMergeSetting({saving:false}));
            return data;
        }).catch((e)=>{
            dispatch(actionMergeSetting({
                affiliate_portal_config: oldConfig,
                saving:false
            }));
        })
    }
};


export const loadSettings = ()=>{
    return (dispatch, getState)=>{
        dispatch(actionMergeSetting({loading:true, under_maintenance:false}));
        return getHttpClient().get('/sites/settings').then(({data})=>{
            dispatch({
                type:ACTIONS.set,
                data:{
                    ...data,
                    loading: false,
                    access_token: getAccessToken(),
                }
            })
        }).catch(()=>{
            dispatch(actionMergeSetting({loading:false, under_maintenance:true}))
        })
    }
};

export function useSettings(){
    const access_token = useSelector((state)=>state.settings.access_token)
    const dispatch = useDispatch()
    useEffect(()=>{
        console.log('use effect called in settings loader')
        if(access_token){
            console.log('loading settings')
            dispatch(loadSettings())
        }
    },[access_token])
}
