import React, {useCallback} from 'react';
import ReactStrapModal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ProgressLoader from "./ProgressLoader";
import PropTypes from 'prop-types';

type SubmitFn = (e: any) => void
export default function Modal({
                                  confirmLoading,
                                  onOk,
                                  fade,
                                  onCancel,
                                  onClose,
                                  okText,
                                  cancelText,
                                  title,
                                  visible,
                                  onSubmit,
                                  children,
                                  size,
                                  headerLink,
                                  disabled
                              }: {
    confirmLoading?: boolean, onOk?: SubmitFn,
    fade?: boolean, onCancel?: SubmitFn,
    onClose?: SubmitFn,
    okText?: string,
    cancelText?: string,
    title?: string, visible: boolean, onSubmit?: SubmitFn,
    children: React.ReactNode,
    size?: 'lg' | 'sm',
    headerLink?: string,
    disabled?: boolean
}) {
    const submitted = useCallback((e) => {
        if (e) {
            if ('preventDefault' in e) {
                e.preventDefault()
            }
            if ('stopPropagation' in e) {
                e.stopPropagation()
            }
        }
        if (onSubmit) {
            onSubmit(e)
        } else if (onOk) {
            onOk(e)
        } else if (onCancel) {
            onCancel(e)
        }

    }, [onSubmit, onOk, onCancel])
    return (
        <ReactStrapModal isOpen={visible} fade={fade} toggle={onCancel || onClose} size={size}>
            {
                (title) &&
                <ModalHeader toggle={onCancel || onClose}>
                    {title}
                    {headerLink && <a href={headerLink} target={"_blank"}
                                      className={"btn btn-sm btn-primary ml-3 mb-1 text-white text-decoration-none"}>Get
                      Help</a>}
                </ModalHeader>
            }
            <Form onSubmit={onSubmit}>
                <ModalBody>
                    {children}
                </ModalBody>
                {
                    (onCancel || onSubmit || onOk || onCancel) &&
                    <ModalFooter>
                        {
                            onCancel ?
                                <button type={"button"} className={"btn btn-outline mr-1"}
                                        onClick={onCancel}>{cancelText || "Cancel"}</button> :
                                null
                        }
                        {
                            confirmLoading ? <ProgressLoader/> : (onSubmit || onOk || onCancel) ?
                                <button type={"submit"} className={`btn btn-primary`} disabled={disabled}
                                        onClick={submitted}>{okText || "OK"}</button> : null
                        }
                    </ModalFooter>
                }
            </Form>
        </ReactStrapModal>
    );
}


function Form({onSubmit, children}) {
    return onSubmit ? <form onSubmit={onSubmit}>{children}</form> : children
}

Modal.defaultProps = {
    fade: true,
}

Modal.propTypes = {
    title: PropTypes.string,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    confirmLoading: PropTypes.bool,
    onOk: PropTypes.func,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'sm'])
}
