import React, {useEffect} from 'react';
import {getSquareConnectUrl} from "./connectSquare";
import Spinner from "../../../components/Spinner";
const url = getSquareConnectUrl(process.env.NODE_ENV === "development")
function SquareUpIntegrationIndex() {

    useEffect(()=>{
        window.location = url
    },[])

    return (
        <div className="container flex align-items-center justify-content-center">
            <div className={"py-4 my-4 text-center"}>
                  <Spinner />
            </div>
        </div>
    );
}

export default SquareUpIntegrationIndex;
