import {getHttpClient} from '../../api';

export const ACTIONS = {
    set:'set-pagesections',
    del: 'del-pagesections',
    update: 'update-pagesections',
    add:'add-pagesections'
};

const actionSetSections = (data) => {
    return {
        type: ACTIONS.set,
        data
    }
}

const actionDeleteSections = (section_id) => {
    return {
        type: ACTIONS.del,
        section_id
    }
}

const actionUpdateSections = (section_id, change) => {
    return {
        type: ACTIONS.update,
        section_id,
        change
    }
}

const actionAddSection = (section_id, data) => {
    return {
        type: ACTIONS.add,
        section_id,
        data
    }
}


export const addSection = (section_id,component)=>{
    return (dispatch, getState)=>{
        return getHttpClient().post('/sites/pagebuilder', {
            section_id, section_data: {component, key: section_id}
        }).then((e)=>{
            dispatch(actionAddSection(section_id, {
                component,
                key: section_id,
                props:{}
            }))
        })
    }
}

export const deleteSection = (section_id)=>{
    return (dispatch, getState)=>{
        return getHttpClient().delete('/sites/pagebuilder/'+section_id)
            .then((row)=>{
                return   dispatch(loadSections())
            })
    }
}

export const loadSections = (page)=>{
    return (dispatch, getState)=>{
        return getHttpClient().get('/sites/pagebuilder/list').then(({data})=>{
            const sections = {}
            data.forEach((item)=>{
                sections[item.key] = item;
            })
            dispatch(actionSetSections(sections))
        })
    }
}

export const updateSection = (section_id, props)=>{
    return (dispatch, getState)=>{
        const section_data = getState().pageSections[section_id];
        section_data.props = props;
        return getHttpClient().patch('/sites/pagebuilder/'+section_id, {
            section_data,
        }).then((e)=>{
            dispatch(actionUpdateSections(section_id, {props}))
        })
    }
}
