import React, {useEffect, useState} from 'react';
import FormGroup from '../../../components/form/FormGroup';
import {getHttpClient} from '../../../api';
import Button from '../../../components/form/Button';
import CurrencySelector from '../../../components/form/CurrencySelector';
import {getCurrentQueryObject} from '../../../utils/func';
import StartContainer from '../../login/StartContainer';
import {WebflowLogo} from '../PlatformLogos';
import * as localStorage from '../../../utils/storage';

function ApiKeySelector(props) {
  const [apiKey, setApiKey] = useState('');
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setLoading(true);
    if (sites.length === 0) {
      getHttpClient().post('/webflow/getSites', {
        apiKey,
      }).then(({data}) => {
        if (data.sites) {
          if (data.sites.length > 0) {
            setSites(data.sites);
            setSelectedSite(data.sites[0]._id);
          }
        } else {
          setError(data.error);
        }
        setLoading(false);
      }).catch((e) => {
        setLoading(false);
      });
    } else {
      const {name, email, password, website, storeName, currency} = formData;
      getHttpClient().post('/webflow/save_keys', {
        name, email, password, website, storeName, currency,
        merchant_token: mtoken,
        apiKey,
        website_match: selectedSite,
      }).then(({data}) => {
        if (data.access_token) {
          localStorage.setItem('access_token', data.access_token);
          window.location = '/wizard';
        } else {
          setError(data.error || 'Unexpected error');
        }
      });

    }
  };
  const {mtoken} = getCurrentQueryObject();
  const [formData, setFormData] = useState({
    website: '',
    storeName: '',
  });

  const isEnabled = (mtoken ||
          (formData.name && formData.email && formData.password)) &&
      formData.website && formData.storeName && formData.apiToken;

  const onChange = key => e => {
    setFormData({...formData, [key]: e.target.value});
  };

  useEffect(() => {
    if (selectedSite) {
      console.log(sites, selectedSite);
      const site = sites.find((item) => item._id == selectedSite);
      if (site) {
        getHttpClient().post('/webflow/getSiteDetails', {
          apiKey,
          _id: selectedSite,
        }).then(({data}) => {
          const {domain, currency} = data;
          setFormData(
              {...formData, storeName: site.name, website: domain, currency});
        });
      }
    }
  }, [selectedSite]);

  return (
      <Wrapper>
        <form onSubmit={onSubmit}>
          {
              error && <p className="alert alert-danger">
                {error}
              </p>
          }
          {
            sites && sites.length > 0 ? <>
                  <FormGroup label={'Choose website'}>
                    <select onChange={(e) => selectedSite(e.target.value)}
                            className={'form-control'}>
                      {
                        sites.map(({_id, name}) => <option key={_id}
                                                           value={_id}>{name}</option>)
                      }
                    </select>
                  </FormGroup>
                  <AccountData mtoken={mtoken} onChange={onChange}
                               formData={formData}/>
                  <Button className={'btn btn-primary'} loading={loading}
                          onClick={onSubmit}
                          title={'Create store account'}/>
                </>
                : <>
                  <p>Enter API Key from your Webflow admin to connect</p>
                  <FormGroup label={'API KEY'}>
                    <input type="text" className="form-control" value={apiKey}
                           onChange={(e) => setApiKey(e.target.value)}/>
                  </FormGroup>
                  <Button className={'btn btn-primary'} loading={loading}
                          onClick={onSubmit} title={'Submit'}/>
                </>
          }
        </form>
      </Wrapper>
  );
}

function AccountData({mtoken, formData, onChange}) {
  return <>

    {
      !mtoken ? <>
        <div className="row">
          <div className="col-md-6">
            <FormGroup label={'Your name'}>
              <input type="text" placeholder={'John Doe'}
                     className="form-control"
                     value={formData.name} onChange={onChange('name')}/>
            </FormGroup>
          </div>
          <div className="col-md-6">
            <FormGroup label={'Email address'}>
              <input type="email" placeholder={'youremail@example.com'}
                     className="form-control" value={formData.email}
                     onChange={onChange('email')}/>
            </FormGroup>
          </div>
        </div>
        <FormGroup label={'Password'}>
          <input type="password" className="form-control"
                 value={formData.password}
                 onChange={onChange('password')}/>
        </FormGroup>
      </> : null
    }
    <div className="row">
      <div className="col-md-6">
        <FormGroup label={'Store Name'}>
          <input type="text" placeholder={'Acme Inc!'} className="form-control"
                 value={formData.storeName} onChange={onChange('store_name')}/>
        </FormGroup>
      </div>
      <div className="col-md-6">
        <FormGroup label={'Website'}>
          <input type="website" placeholder={'https://example.com'}
                 className="form-control" value={formData.website}
                 onChange={onChange('website')}/>
        </FormGroup>
      </div>
    </div>
    <FormGroup label={'Currency'}>
      <CurrencySelector value={formData.currency}
                        onChange={onChange('currency')}/>
    </FormGroup>
  </>;
}

function Wrapper({children}) {
  return <StartContainer title={'Goaffpro + Webflow integration'}>
    <div className="d-flex align-items-center justify-content-center mb-4">
      <WebflowLogo/></div>
    <div className="card">
      <div className="card-header">Integrate Goaffpro with Webflow store</div>
      <div className="card-body">
        {children}
      </div>
    </div>
  </StartContainer>;
}

export default ApiKeySelector;
