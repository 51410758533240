import {appURL, baseURL} from './api';
import currencies from './currencies';
import {isShopifyEmbedTestMode} from './utils/shopify-embed-testing';

export const domain = 'goaffpro.com';
export const support_email = 'support@goaffpro.com';

const powerAmp = {
  clientId: '91fbb1cdc42a61c4092e7539beebb2a3',
  clientSecret:'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',
  scopes: 'read_orders,write_orders,read_customers,write_customers,read_script_tags,write_script_tags,read_products,read_price_rules,write_price_rules,read_themes,write_themes,write_content,read_content',
  redirect_url: process.env.NODE_ENV === 'development' ?
      'http://localhost:3000/shopify/auth_callback' :
      'https://poweramp.goaffpro.app/shopify/auth_callback',
}

export const shopify = {
  clientId: 'eda11fcd576a556494594ddd4660db71',
  scopes: 'read_orders,write_orders,read_customers,write_customers,read_script_tags,write_script_tags,read_products,read_price_rules,write_price_rules,read_themes,write_themes,write_content,read_content',
  redirect_url: process.env.NODE_ENV === 'development' ?
      'http://localhost:3000/shopify/auth_callback' :
      'https://app.goaffpro.com/shopify/auth_callback',
};

export const wixConfig = {
  appId: '1129148b-e312-4228-ad46-9e10c1f22229',
  redirect_url: 'https://app.goaffpro.com/wix/callback',
};

export const woocommerce = {
  query: {
    user_id: '1',
    app_name: 'GoAffPro',
    scope: 'read_write',
    return_url: appURL + '/woocommerce/auth_callback', //the user is sent here
    callback_url: `${baseURL}/woocommerce/install`, //the consumer key and secret are sent here
  },
  endpoint: 'index.php/wc-auth/v1/authorize',
};

export const subscriptions = [
  'free',
  'basic',
  'premium',
  'enterprise',
];

export const langmap = {
  'en': 'English',
  'fr': 'French',
  'de': 'German',
  el: 'Greek',
  iw: 'Hebrew',
  hu: 'Hungarian',
  it: 'Italian',
  tr: 'Turkish',
  'nb': 'Norwegian',
  'pt': 'Portuguese',
  'pl': 'Polish',
  'ru': 'Russian',
  'es': 'Spanish',
  'sv': 'Swedish',
  'vi': 'Vietnamese',
  'ar': 'Arabic',
  'nl': 'Dutch',
  'ja': 'Japanese',
  'zh_CN': 'Chinese (Simplified)',
  'zh_TW': 'Chinese (Traditional)',
  'ko': 'Korean',
  'fi': 'Finnish',
  'cs': 'Czech',
  'da': 'Danish',
  'ro': 'Romanian',
  'th': 'Thai',
  'lt': 'Lithuanian',
  'ms': 'Malay',
};

export const defaultConversionPreferenceOrder = [
  'coupon-code', 'customer-affiliate-connect',
  'referral-cookie', 'personal-order',
  'custom-rule', 'system-ref-tracking',
  'cookieless-tracking',
  '~parent-affiliate',
  '~party-link',
  '~subscription-contract',
];

export const curOptions = Object.keys(currencies).map((cur) => {
  return {
    label: currencies[cur].name,
    value: cur,
  };
});
