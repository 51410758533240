import React, { useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import RenderFormInput from "./RenderFormInput";
function FormRenderer(props) {
        const {formFields, isDefaultEnabled, onChange, values} = props;
        const [form, setForm] = useState([])
        const [data, setData] = useState({})
        useEffect(()=>{
            setForm(formFields ? formFields.filter(({isEnabled})=>isEnabled === undefined ? isDefaultEnabled : isEnabled) : [])
        }, [formFields])

        const onValueChange = (key) => (e) => {
            const d = {...data, [key]:e.target.value};
            setData(d)
            if(onChange){
                onChange(d)
            }
        }
        const getValue = (key)=>{
            if(onChange){
                return values ? values[key] : props[key];
            }else {
                return data[key] === undefined ? props[key] : data[key]
            }
        }

        return (
            <>
                <div className={'row flex-wrap'}>
                    {
                        form.map((item, index) =>{
                                const key =   item.id+"_"+index
                                const {isRequired, label, info, placeholder, id, prepend, append, type, size, options} = item;
                            return <RenderFormInput
                                      key={key}
                                      isRequired={isRequired}
                                      label={label}
                                      placeholder={placeholder}
                                      id={id}
                                      prepend={prepend}
                                      append={append}
                                      type={type}
                                      size={size}
                                      info={info}
                                      options={options}
                                      value={getValue(item.id) || ""}
                                      onChange={onValueChange}
                                />
                            })
                    }
                </div>
            </>
        );
    }


FormRenderer.propTypes = {
    formFields: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    isDefaultEnabled: PropTypes.bool
}


export default FormRenderer
