// this file ensures that access token once set for a page is permanently set
// this can serve as a standby until scoping by URL can be established
import Cookies from 'js-cookie';
import store from '../redux/store'
import {actionMergeSetting} from '../redux/settings/settingsAction';
let access_token = localStorage.getItem('access_token') || Cookies.get('access_token');
export const setAccessToken = (token) => {
  access_token = token;
  console.log('setting access token', token)
  localStorage.setItem('access_token', access_token)
  Cookies.set('access_token', access_token);
  console.log('dispatching merge settings')
  store.dispatch(actionMergeSetting({access_token: token}))
};


export const deleteAccessToken = ()=>{
  access_token = undefined;
  localStorage.removeItem('access_token')
  Cookies.remove('access_token');
  store.dispatch(actionMergeSetting({access_token: null}))

}
export const getAccessToken = () => {
  return access_token || localStorage.getItem('access_token');
};
