import React, {useCallback, useEffect} from 'react';

export default function Select({value, options, defaultSelectFirst, disabled, onChange, placeholder, hasEmpty, style}: {
    hasEmpty?: boolean,
    placeholder?: string,
    onChange: (str: string) => void,
    disabled?: boolean,
    defaultSelectFirst?: boolean,
    options: Array<{ label: string, value: string }>,
    value?: string,
    style?: any,
}) {
    const changed = useCallback((e) => {
        if (onChange) {
            onChange(e.target.value)
        }
    }, [onChange])

    useEffect(() => {
        const optionExists = options && options.length > 0 ? options.find((i) => i.value == value) : false
        if (defaultSelectFirst) {
            if (!optionExists || !value) {
                if (options.length > 0) {
                    onChange(options[0].value)
                }
            }
        }
    }, [defaultSelectFirst, onChange, options, value])

    return (
        <select disabled={disabled} style={style} className={"form-control"} value={value} onChange={changed}>
            {
                hasEmpty ? <option value={undefined}>{placeholder}</option> : null
            }
            {
                options.map(({label, value}) => {
                    return <option value={value} key={value}>{label}</option>
                })
            }
        </select>
    );
}
