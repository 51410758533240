import React, {Component} from 'react';
import StartContainer from '../login/StartContainer';
import Label from '../../components/form/Label';
import Button from '../../components/form/Button';
import {getHttpClient} from '../../api';
import DocumentTitle from '../../components/DocumentTitle';
import * as localStorage from '../../utils/storage';

class ManagerLogin extends Component {

  state = {
    email: '',
    password: '',
  };

  onChange = (key) => (e) => {
    this.setState({[key]: e.target.value});
  };

  onSubmit = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const {email, password} = this.state;
    this.setState({loading: true, error: false});
    getHttpClient().post('/manager/login-master', {
      email, password,
    }).then(({data}) => {
      if (data.manager_access_token) {
        localStorage.setItem('manager_access_token', data.manager_access_token);
        if (data.sites.length > 1) {
          window.location = '/master';
        } else {
          localStorage.setItem('access_token', data.sites[0].access_token);
          window.location = '/';
        }
      } else {
        this.setState({error: 'Invalid credentials'});
      }
      this.setState({loading: false});
    }).catch(() => {
      this.setState({loading: false, error: 'Network error'});
    });
  };

  render() {
    return (
        <StartContainer>
          <DocumentTitle title={'Admin Login'}/>
          <div className="text-center mb-2">
            <img src={'/icon-square.svg'} width={50} height={50}/>
          </div>
          <h1 className={'text-center'}>Manager Login</h1>
          <div className="card shadow-lg">
            <form className="card-body" onSubmit={this.onSubmit}>
              {
                  this.state.error &&
                  <p className="alert alert-danger">{this.state.error}</p>
              }
              <div className="form-group">
                <Label title={'Username or Email address'}/>
                <input type="text" name={'username'} value={this.state.email}
                       onChange={this.onChange('email')}
                       className="form-control"/>
              </div>

              <div className="form-group">
                <Label title={'Password'}/>
                <input type="password" name={'password'}
                       value={this.state.password}
                       onChange={this.onChange('password')}
                       className="form-control"/>
              </div>
              <Button disabled={!this.state.email || !this.state.password}
                      loading={this.state.loading} onClick={this.onSubmit}
                      className={'btn btn-primary'}>Login</Button>
            </form>
          </div>
        </StartContainer>
    );
  }
}

export default ManagerLogin;
