import React, {useEffect, useState} from 'react';
import useQuery from "../../../components/hooks/useQuery";
import {getHttpClient} from "../../../api";
import Spinner from "../../../components/Spinner";
import {Link} from "react-router-dom";

function SquareUpCallback(props) {
    const {code} = useQuery()
    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState(false)
    useEffect(()=>{
        if(code){
            setLoading(true)
            getHttpClient()
                .post('/integrations/square/callback',{
                    code
                }).then(({data})=>{
                    setLoading(false)
                if(data.success){
                    //connection was successful
                    setSuccess(true)
                }
            }).catch((e)=>{
                setLoading(false)
            })
        }
    },[code])

    return (
        <div className="container flex align-items-center justify-content-center">
            <div className={"py-4 my-4 text-center"}>
                {
                    loading ? <Spinner/> : <>
                        {
                            success ?
                                <p className="text-success text-center">The checkout was successfully connected <br/>
                                    <Link to={"/"}>Go to dashboard</Link>
                                </p> :
                                <p className="text-danger text-center">Error in connecting the square checkout. <br/>
                                    <Link to={"/square"}>Try again</Link>
                                </p>
                        }
                    </>
                }
            </div>
        </div>
    );
}

export default SquareUpCallback;
