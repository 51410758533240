
const defaultState = {
    "logo_link":"https://google.com",
}

export default (state = defaultState, action)=>{
    switch (action.type) {
        case "update-component-values":
            return {...state, ...action.change}
        default:
            return state;
    }
}
