import React, {useCallback, useEffect, useRef, useState} from 'react';
import SignatureCanvas from 'react-signature-canvas';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import useToggle from '../hooks/useToggle';

function SignatureInput({value, onChange}) {
    const changed = useCallback((value)=>{
        if(onChange) {
            onChange({
                target: {
                    value,
                    dataURL: value,
                }
            })
        }
    },[onChange])

    const [visible, toggle] = useToggle()
    if(onChange) {
        return (<div>
                <button className={'btn btn-outline-light'}
                        onClick={onChange ? toggle : null}>
                    <img src={value || '/signature.png'} alt=""
                         style={{width: 100}}/>
                </button>
                <SignatureInputModal visible={visible} toggle={toggle}
                                     updateSignature={changed}/>
            </div>
        );
    }else if(Array.isArray(value)){
        return <RenderCanvasWithPoints value={value} />
    } else{
            return <img src={value} alt=""
                        style={{width: 300}}/>
    }
}



function RenderCanvasWithPoints({value}){
    const signatureCanvasRef = useRef()
    useEffect(()=>{
        if(Array.isArray(value) && signatureCanvasRef && signatureCanvasRef.current){
            signatureCanvasRef.current.fromData(value)
        }
    },[value])
    return  <SignatureCanvas
        ref={signatureCanvasRef}
        penColor='blue'
        canvasProps={{width: 300, height: 200, className: 'sigCanvas'}}
    />
}
function SignatureInputModal({visible, updateSignature, toggle}){
    const [value, setValue] = useState()
    const signatureCanvasRef = useRef()
    const clearCanvas = useCallback(() => {
        signatureCanvasRef.current.fromData([]);
    },[signatureCanvasRef])
    useEffect(()=>{
        if(signatureCanvasRef && signatureCanvasRef.current){
            signatureCanvasRef.current.fromDataURL(value)
        }
    }, [value, signatureCanvasRef])
    const onClose = useCallback((e)=>{
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
        updateSignature(signatureCanvasRef.current.toDataURL('img/png'))
        toggle()
    },[toggle, updateSignature])

    return     <Modal onCancel={toggle} title={"Signature"} onSubmit={onClose} visible={visible}>
        <div className="border" style={{width: 300}}>
            <SignatureCanvas
                onEnd={setValue} ref={signatureCanvasRef}
                penColor='blue'
                canvasProps={{width: 300, height: 200, className: 'sigCanvas'}}
            />

            { value ?
                <button type={"button"} className={"btn btn-link text-danger"}
                        onClick={clearCanvas}>Clear</button> : null
            }
        </div>
    </Modal>
}

SignatureInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array,
}

export default SignatureInput;
