import React, {useCallback} from 'react';

function Input(props) {
  const changed = useCallback((e)=>{
    if(props.onChange){
      props.onChange(e.target.value)
    }
  },[props])
  return (
      <Wrapper prefix={props.prefix} suffix={props.suffix}>
        <input className={"form-control"} {...props} onChange={changed}/>
      </Wrapper>
  );
}

function Wrapper({prefix, children, suffix}){
  if(prefix || suffix){
    return <div className="input-group">
    {
        prefix &&             <div className="input-group-prepend">
          <span className="input-group-text">{prefix}</span>
        </div>

    }
      {children}
    {
        suffix &&
        <div className="input-group-append">
          <span className="input-group-text">{suffix}</span>
        </div>
    }
  </div>
    }
  return children
}

export default Input;
