module.exports = {
    /*
    "Payment plans":"Payment plans",
    "Payment plans subtitle":"Set up payment plans for your affiliates. Once you set this up, you will be able to add these payment plans to your sign up pages",
    "New payment plan":"New payment plan",
    "Type above to search": "Type above to search",
    "Type here to search for affiliates": "Type here to search for affiliates",
    "Orders": "Orders",
    "Orders over time": "Orders over time",
    "Affiliate Revenue": "Affiliate Revenue",
    "Revenue over time": "Revenue over time",
    "Unique Visits": "Unique Visits",
    "Visits over time": "Visits over time",
    "Search for Collections or Categories": "Search for Collections or Categories",
    "Saved": "Saved",
    "Clear": "Clear",
    "Open in a new tab": "Open in a new tab",
    "Delete": "Delete",
    "Uploading": "Uploading",
    "Re-Arrange": "Re-Arrange",
    "Edit fields": "Edit fields",
    "Add field": "Add field",
    "No Label": "No Label",
    "Field label": "Field label",
    "Field type": "Field type",
    "Input Field": "Input Field",
    "Input Area": "Input Area",
    "Checkbox": "Checkbox",
    "Dropdown": "Dropdown",
    "Multiple Select": "Multiple Select",
    "Options (one per line)": "Options (one per line)",
    "Required field": "Required field",
    "Display Width": "Display Width",
    "Delete Field": "Delete Field",
    "Add your own field": "Add your own field",
    "New Input Field": "New Input Field",
    "Change": "Change",
    "Add": "Add",
    "Field title": "Field title",
    "Options": "Options",
    "One option per line": "One option per line",
    "Required": "Required",
    "Type": "Type",
    "Multiple Selection": "Multiple Selection",
    "Radio": "Radio",
    "File Upload": "File Upload",
    "Signature": "Signature",
    "Width": "Width",
    "Field info": "Field info",
    "Input mask (Advanced)": "Input mask (Advanced)",
    "Invalid CSV File": "Invalid CSV File",
    "Reload App": "Reload App",
    "per page": "per page",
    "Previous": "Previous",
    "Next": "Next",
    "Hide gallery": "Hide gallery",
    "Choose from Gallery": "Choose from Gallery",
    "Upload your own": "Upload your own",
    "Search": "Search",
    "No photos found": "No photos found",
    "Search for Products": "Search for Products",
    "Choose an element": "Choose an element",
    "Choose options": "Choose options",
    "QR Code": "QR Code",
    "Order Date": "Order Date",
    "Order Number": "Order Number",
    "Filter": "Filter",
    "Select Order": "Select Order",
    "Vimeo video": "Vimeo video",
    "Youtube video": "Youtube video",
    "Go Back": "Go Back",
    "No Data": "No Data",
    "Bulk Mode": "Bulk Mode",
    "Upgrade to activate bulk mode": "Upgrade to activate bulk mode",
    "Edit": "Edit",
    "Close": "Close",
    "Save": "Save",
    "Percentage": "Percentage",
    "Flat Rate": "Flat Rate",
    "Confirm action": "Confirm action",
    "Confirm": "Confirm",
    "Copied": "Copied",
    "Copy": "Copy",
    "Choose a column": "Choose a column",
    "Days": "Days",
    "Hours": "Hours",
    "Minutes": "Minutes",
    "Drop the files here": "Drop the files here",
    "Drag and drop some files here": "Drag and drop some files here, or click to select files",
    "API keys are invalid": "API keys are invalid",
    "Your affiliate program is currently not running": "Your affiliate program is currently not running",
    "TURN ON": "TURN ON",
    "Your woocommerce store does not appear to be integrated": "Your woocommerce store does not appear to be integrated",
    "Do the integration here": "Do the integration here",
    "Goaffpro plugin is not installed or activated on your magento store": "Goaffpro plugin is not installed or activated on your magento store",
    "Magento integration credentials are invalid": "Magento integration credentials are invalid",
    "Squarespace credentials are expired": "Squarespace credentials are expired",
    "Click here to reconnect": "Click here to reconnect",
    "Square Checkout credentials are expired": "Square Checkout credentials are expired",
    "Kindly reinstall the app": "Kindly reinstall the app",
    "Kindly re-connect the app to the store": "Kindly re-connect the app to the store",
    "You do not have permission to view this page": "You do not have permission to view this page",
    "Get Help": "Get Help",
    "per order": "per order",
    "Accept": "Accept",
    "Reject": "Reject",
    "Loading": "Loading",
    "Drag 'n' drop your creatives to upload": "Drag 'n' drop your creatives to upload, or click to select files",
    "Need Help": "Need Help",
    "Get in touch": "Get in touch",
    "Contact us": "Contact us",
    "Like the app ? Review us on the App Store": "Like the app ? Review us on the App Store",
    "App Store": "App Store",
    "Upgrade": "Upgrade",
    "Targets": "Targets",
    "Form referrals": "Form referrals",
    "Connections": "Connections",
    "Paypal Payouts": "Paypal Payouts",
    "Tipalti Payouts": "Tipalti Payouts",
    "Influencer Seeding": "Influencer Seeding",
    "Affiliate Pages": "Affiliate Pages",
    "Affiliate Files": "Affiliate Files",
    "Membership Plans": "Membership Plans",
    "Compensation Plans": "Compensation Plans",
    "Scripts": "Scripts",
    "Showcases": "Showcases",
    "Groups": "Groups",
    "Refer and Earn": "Refer and Earn",
    "Billing": "Billing",
    "Dashboard": "Dashboard",
    "Mobile App": "Mobile App",
    "Look and Feel": "Look and Feel",
    "Commissions": "Commissions",
    "Coupons": "Coupons",
    "Affiliates": "Affiliates",
    "Sales": "Sales",
    "Multi Level": "Multi Level",
    "Analytics": "Analytics",
    "Payouts": "Payouts",
    "Bulk Mail": "Bulk Mail",
    "Creatives": "Creatives",
    "Marketplace": "Marketplace",
    "Settings": "Settings",
    "Post Checkout Popup": "Post Checkout Popup",
    "App under maintenance": "App under maintenance",
    "The admin panel is currently under going maintenance": "The admin panel is currently under going maintenance and hence is not available. Kindly check back in a few minutes",
    "if this message persists for more than 5 minutes": "if this message persists for more than 5 minutes",
    "Two factor auth": "Two factor auth",
    "Open your authenticator app": "Open your authenticator app and enter the generated TOTP to continue",
    "Not able to log in?": "Not able to log in?",
    "Stores": "Stores",
    "Logout": "Logout",
    "Choose a plan": "Choose a plan",
    "FREE": "FREE",
    "Unlimited affiliates": "Unlimited affiliates",
    "Unlimited sales": "Unlimited sales",
    "Unlimited revenue": "Unlimited revenue",
    "Basic affiliate portal": "Basic affiliate portal",
    "Choose": "Choose",
    "14-day free trial included": "14-day free trial included",
    "Branded affiliate portal": "Branded affiliate portal",
    "Targets and Bonuses": "Targets and Bonuses",
    "Multiple Groups and Sign up pages": "Multiple Groups and Sign up pages",
    "Automatic Invoices": "Automatic Invoices",
    "W9 Form collection": "W9 Form collection",
    "Multi Level marketing": "Multi Level marketing",
    "S2S callbacks": "S2S callbacks",
    "Affiliate page builder": "Affiliate page builder",
    "Embedded affiliate store": "Embedded affiliate store",
    "Third party integrations": "Third party integrations",
    "Current plan": "Current plan",
    "File": "File",
    "Affiliate": "Affiliate",
    "Created": "Created",
    "Select": "Select",
    "Actions": "Actions",
    "Edit plan": "Edit plan",
    "New plan": "New plan",
    "Gold plan": "Gold plan",
    "Plan name (optional)": "Plan name (optional)",
    "Plan type": "Plan type",
    "One time fees": "One time fees",
    "Recurring fees": "Recurring fees",
    "Fees": "Fees",
    "Interval": "Interval",
    "Month": "Month",
    "Week": "Week",
    "Year": "Year",
    "Day": "Day",
    "My plan has a free trial period": "My plan has a free trial period",
    "Trial duration": "Trial duration",
    "Unit": "Unit",
    "Affiliate tag": "Affiliate tag",
    "Applies the tag to the affiliate who purchases this plan": "Applies the tag to the affiliate who purchases this plan",
    "Plan Perks (optional)": "Plan Perks (optional)",
    "One per line": "One per line",
    "Plan Description (optional)": "Plan Description (optional)",
    "Payment gateway is not integrated": "Payment gateway is not integrated. You will not be able to save your payment plans until payment gateway is set",
    "DEBUG LOG": "DEBUG LOG",
    "Add subscriber in group": "Add subscriber in group",
    "Recruitment bonus": "Recruitment bonus",
    "Reward given to recruiter when the affiliate subscribes to this plan": "Reward given to recruiter when the affiliate subscribes to this plan",
    "Associated signup page": "Associated signup page",
    "Only show this membership plan if affiliate signs up on the set signup page": "Only show this membership plan if affiliate signs up on the set signup page",
    "Preview": "Preview",
    "Payment Plans": "Payment Plans",
    "Enabled": "Enabled",
    "Payment gateway not set to accept to accept payments": "Payment gateway not set to accept to accept payments",
    "Setup Payment Gateway": "Setup Payment Gateway",
    "Payment gateway is correctly set": "Payment gateway is correctly set",
    "Disconnect": "Disconnect",
    "Setup PayPal Payment Gateway": "Setup PayPal Payment Gateway",
    "Allows you to collect membership fees from your affiliates": "Allows you to collect membership fees from your affiliates",
    "PayPal My Apps": "PayPal My Apps",
    "Client ID": "Client ID",
    "Client Secret": "Client Secret",
    "SANDBOX ??": "SANDBOX ??",
    "Sandbox credentials": "Sandbox credentials",
    "FAQ": "FAQ",
    "Please ensure the following": "Please ensure the following",
    "You have a Business PayPal account": "You have a Business PayPal account",
    "You are entering LIVE credentials and not SANDBOX credentials": "You are entering LIVE credentials and not SANDBOX credentials",
    "PayPal Setup FAQ": "PayPal Setup FAQ",
    "What is Client ID and Client Secret": "What is Client ID and Client Secret",
    "They are the application credentials required to create payment plans for your affiliates": "They are the application credentials required to create payment plans for your affiliates. See the screencast to know how to generate it",
    "Invalid Credentials error ??": "Invalid Credentials error ??",
    "Downline": "Downline",
    "Filter by affiliate": "Filter by affiliate",
    "Download": "Download",
    "Earnings": "Earnings",
    "Visits": "Visits",
    "Conversion": "Conversion",
    "Revenue": "Revenue",
    "Commission": "Commission",
    "Rank": "Rank",
    "No. of Orders": "No. of Orders",
    "R = Referring Page": "R = Referring Page",
    "L = Landing Page": "L = Landing Page",
    "Leaderboard": "Leaderboard",
    "Referred From": "Referred From",
    "Landing Page": "Landing Page",
    "Page Views": "Page Views",
    "Top Sites": "Top Sites",
    "Top countries": "Top countries",
    "Top Affiliates": "Top Affiliates",
    "OOPS!": "OOPS!",
    "The system encountered some error while processing your subscription": "The system encountered some error while processing your subscription. Contact us to activate your subscription",
    "React Stripe Elements Example": "React Stripe Elements Example",
    "Affiliate Commissions": "Affiliate Commissions",
    "Affiliates with different commission than default": "Affiliates with different commission than default",
    "Set commission for an affiliate": "Set commission for an affiliate",
    "Set commission for affiliates": "Set commission for affiliates",
    "Submit": "Submit",
    "Sync": "Sync",
    "Collection Commissions": "Collection Commissions",
    "Set commissions on entire Collections or Categories of products": "Set commissions on entire Collections or Categories of products",
    "New Collection Commission": "New Collection Commission",
    "Upgrade to activate": "Upgrade to activate",
    "Sync All": "Sync All",
    "Change commission": "Change commission",
    "Product": "Product",
    "Any Affiliate": "Any Affiliate",
    "Set Commission": "Set Commission",
    "Commission Based on Collections": "Commission Based on Collections",
    "Collection": "Collection",
    "Commission Modifier": "Commission Modifier",
    "Add Modifier": "Add Modifier",
    "Customer Shipping State": "Customer Shipping State",
    "Customer Shipping Country": "Customer Shipping Country",
    "Order value is": "Order value is",
    "Total quantity of items is": "Total quantity of items is",
    "First time customer": "First time customer",
    "Old customer/Recurring Customer": "Old customer/Recurring Customer",
    "Coupon code matches": "Coupon code matches",
    "Customer email matches": "Customer email matches",
    "Customer's first time product purchase": "Customer's first time product purchase",
    "Customer's recurring product purchase": "Customer's recurring product purchase",
    "Customer tag matches": "Customer tag matches",
    "Order tag matches": "Order tag matches",
    "Payment gateway matches": "Payment gateway matches",
    "Expression": "Expression",
    "First sale of the affiliate": "First sale of the affiliate",
    "Other Adjustments": "Other Adjustments",
    "Product Name": "Product Name",
    "Matches": "Matches",
    "Value": "Value",
    "Adjust commission by": "Adjust commission by",
    "Rule": "Rule",
    "Adjustment": "Adjustment",
    "Delete modifier ?": "Delete modifier ?",
    "Commission Modifiers": "Commission Modifiers",
    "Modify affiliate commission based on the set rules.": "Modify affiliate commission based on the set rules. Intended for advanced users only.",
    "Add new modifier": "Add new modifier",
    "Royalties on Collections": "Royalties on Collections",
    "Products": "Products",
    "Partner": "Partner",
    "Fixed Product Commissions": "Fixed Product Commissions",
    "Items": "Items",
    "Fixed Commissions": "Fixed Commissions",
    "Doesn't matter who refers the sale, the set affiliate will also get the commission.": "Doesn't matter who refers the sale, the set affiliate will also get the commission.",
    "Applies on referred sales": "Applies on referred sales",
    "Applies on all sales": "Applies on all sales",
    "Applies on non-referred sales": "Applies on non-referred sales",
    "Set Royalties on a Product": "Set Royalties on a Product",
    "Set Royalties on a Collection": "Set Royalties on a Collection",
    "Rule based royalties": "Rule based royalties",
    "Set up rules which determine how royalties are given.": "Set up rules which determine how royalties are given.",
    "Requires upgrade": "Requires upgrade",
    "New rule": "New rule",
    "Action": "Action",
    "No royalty rules created": "No royalty rules created",
    "Edit rule": "Edit rule",
    "New royalty commission rule": "New royalty commission rule",
    "If (choose rule)": "If (choose rule)",
    "Product name": "Product name",
    "Product Tag": "Product Tag",
    "Product Vendor": "Product Vendor",
    "Product Type": "Product Type",
    "Test": "Test",
    "Equals": "Equals",
    "Contains": "Contains",
    "Starts With": "Starts With",
    "Ends With": "Ends With",
    "Rule based tracking": "Rule based tracking",
    "Set up rules which determine how sales are tracked.": "Set up rules which determine how sales are tracked.",
    "Edit rules": "Edit rules",
    "Condition": "Condition",
    "Give commission to": "Give commission to",
    "No tracking rules created": "No tracking rules created",
    "Customer Postal Code (Zip Code)": "Customer Postal Code (Zip Code)",
    "Attribute sale to": "Attribute sale to",
    "Select your product": "Select your product",
    "Any Product": "Any Product",
    "Type product name to search": "Type product name to search",
    "Variation (optional)": "Variation (optional)",
    "Product Commissions": "Product Commissions",
    "Set different commissions for different products and/or affiliates": "Set different commissions for different products and/or affiliates",
    "New Product Commission": "New Product Commission",
    "Create Product Commission": "Create Product Commission",
    "Try new Bulk CSV Upload tool": "Try new Bulk CSV Upload tool",
    "NEW": "NEW",
    "Update": "Update",
    "Referral Commissions": "Referral Commissions",
    "Royalties (Fixed commissions)": "Royalties (Fixed commissions)",
    "Step": "Step",
    "Fill the CSV file with the commission rates and upload below.": "Fill the CSV file with the commission rates and upload below.",
    "CSV Imported successfully": "CSV Imported successfully",
    "Open commission page": "Open commission page",
    "Variation": "Variation",
    "Commission Rate": "Commission Rate",
    "Click the button below to download the prepared spreadsheet": "Click the button below to download the prepared spreadsheet",
    "Prepare download": "Prepare download",
    "If you have already downloaded the CSV file": "If you have already downloaded the CSV file. Proceed to step 2",
    "Apply on each item": "Apply on each item",
    "Apply on entire order": "Apply on entire order",
    "Default Commission": "Default Commission",
    "Applies to all products and affiliates": "Applies to all products and affiliates",
    "Given for entire order": "Given for entire order",
    "Given for each product item": "Given for each product item",
    "The commission is set to be given on entire order": "The commission is set to be given on entire order. To set product specific commissions, either set default commission to be given on per product basis or as a percentage of order",
    "Plan report": "Plan report",
    "Data explorer": "Data explorer",
    "Plan Code": "Plan Code",
    "In queue": "In queue",
    "Unexpected error": "Unexpected error",
    "Processing": "Processing",
    "Undo Commit": "Undo Commit",
    "Commit": "Commit",
    "Projection for": "Projection for",
    "ID": "ID",
    "Date": "Date",
    "Status": "Status",
    "Edit Plan": "Edit Plan",
    "Run Plan": "Run Plan",
    "Do a projection run": "Do a projection run",
    "History": "History",
    "Show projection runs": "Show projection runs",
    "Status Filters": "Status Filters",
    "Enqueued": "Enqueued",
    "Running": "Running",
    "Error": "Error",
    "Pending Commit": "Pending Commit",
    "Committed": "Committed",
    "Test Run": "Test Run",
    "Plan name": "Plan name",
    "Plan code": "Plan code",
    "Confirm deletion of this compensation plan ?": "Confirm deletion of this compensation plan ? Deleting this plan does not affect the previous compensations",
    "Delete Plan": "Delete Plan",
    "Compensation plans": "Compensation plans",
    "Build advanced compensation plans for your affiliates.": "Build advanced compensation plans for your affiliates.",
    "Have a query, suggestion, feedback?": "Have a query, suggestion, feedback? Need custom feature built into the app? Facing issues with app setup ?",
    "Send us an email at": "Send us an email at",
    "Our support team is available 24x7.": "Our support team is available 24x7. Or you can use the contact form below.",
    "Your message": "Your message",
    "Send Message": "Send Message",
    "Helpful Resources": "Helpful Resources",
    "Knowledge base": "Knowledge base",
    "YouTube Channel": "YouTube Channel",
    "Blog": "Blog",
    "Setup Automatic Coupons": "Setup Automatic Coupons",
    "Automatic coupons are assigned to your affiliates when they register.": "Automatic coupons are assigned to your affiliates when they register.",
    "Discount type": "Discount type",
    "Percentage off": "Percentage off",
    "Flat rate discount": "Flat rate discount",
    "Free shipping": "Free shipping",
    "Discount Value": "Discount Value",
    "Usage Limits": "Usage Limits",
    "Limit to one use per customer": "Limit to one use per customer",
    "Limit number of times this discount can be used in total": "Limit number of times this discount can be used in total",
    "Number of uses": "Number of uses",
    "Advanced": "Advanced",
    "Minimum order value": "Minimum order value",
    "Limit discount to products": "Limit discount to products",
    "Tip: Want greater customization.": "Tip: Want greater customization.",
    "See Advanced Setup": "See Advanced Setup",
    "Automatic Coupons": "Automatic Coupons",
    "Generate coupons automatically for every registered affiliate": "Generate coupons automatically for every registered affiliate",
    "Create for existing affiliates": "Create for existing affiliates",
    "Disable": "Disable",
    "Setup": "Setup",
    "FREE SHIPPING": "FREE SHIPPING",
    "Bulk refresh coupon code": "Bulk refresh coupon code",
    "Download spreadsheet of affiliate with their coupon codes.": "Download spreadsheet of affiliate with their coupon codes. Update the coupon codes in the spreadsheet and upload below to update affiliate's coupon codes",
    "Affiliates with coupons": "Affiliates with coupons",
    "All affiliates": "All affiliates",
    "Affiliates without coupons": "Affiliates without coupons",
    "Upload the \"changed\" spreadsheet to update the coupons in bulk": "Upload the \"changed\" spreadsheet to update the coupons in bulk",
    "Nothing to update": "Nothing to update",
    "Completed": "Completed",
    "Coupon style": "Coupon style",
    "Change Style": "Change Style",
    "Current Sequence number": "Current Sequence number",
    "Sequence number for next registration would be": "Sequence number for next registration would be",
    "Dictionary Word": "Dictionary Word",
    "Random String": "Random String",
    "Instagram Handle": "Instagram Handle",
    "Sequence": "Sequence",
    "Affiliate's Name": "Affiliate's Name",
    "Referral": "Referral",
    "Personal": "Personal",
    "Filters": "Filters",
    "Toggle select all": "Toggle select all",
    "Delete selected": "Delete selected",
    "Exit bulk mode": "Exit bulk mode",
    "Bulk actions": "Bulk actions",
    "Filter by coupon": "Filter by coupon",
    "Filter by type": "Filter by type",
    "Referral coupons": "Referral coupons",
    "Personal coupons": "Personal coupons",
    "Coupon code": "Coupon code",
    "Also create in store": "Also create in store",
    "The coupon will be created by the app with the discount value as set in Automatic Coupons": "The coupon will be created by the app with the discount value as set in Automatic Coupons",
    "For coupon to work, you must create it in your store": "For coupon to work, you must create it in your store",
    "For affiliate's information only": "For affiliate's information only",
    "Discount amount": "Discount amount",
    "Setup Personal Coupons": "Setup Personal Coupons",
    "Discount code will be generated for your affiliates for personal use": "Discount code will be generated for your affiliates for personal use. These discount codes do not generate commission.",
    "Personal Coupons": "Personal Coupons",
    "Give discount codes to your affiliates for personal use.": "Give discount codes to your affiliates for personal use. These coupon codes do not generate any commission for the affiliate",
    "discount coupon code will be automatically generated for affiliate's personal use": "discount coupon code will be automatically generated for affiliate's personal use",
    "Random Text": "Random Text",
    "discount coupon code will be automatically generated for every registered affiliate": "discount coupon code will be automatically generated for every registered affiliate",
    "FREE Shipping": "FREE Shipping",
    "Percentage OFF": "Percentage OFF",
    "Fixed amount discount": "Fixed amount discount",
    "Discount value": "Discount value",
    "Advanced Options": "Advanced Options",
    "Limit to collection": "Limit to collection",
    "Total uses": "Total uses",
    "Maximum uses per customer": "Maximum uses per customer",
    "Automatic coupons are assigned to your affiliates when they register": "Automatic coupons are assigned to your affiliates when they register. They are super useful when your affiliates promote your products over instagram",
    "Individual use": "Individual use",
    "Automatic coupons are assigned to your affiliates when they sign up.": "Automatic coupons are assigned to your affiliates when they sign up. The affiliates can share these coupon codes with their customers. When their customers use these coupon code at checkout, the customer gets the discount and the affiliate gets the commission",
    "Tip: Want greater customization. See Advanced Setup": "Tip: Want greater customization. See Advanced Setup",
    "Apply discount only if minimum order value is": "Apply discount only if minimum order value is",
    "Give discount codes to your affiliates for sharing with their customers": "Give discount codes to your affiliates for sharing with their customers. Their customers can now come to your store without affiliate's link and use the affiliate's coupon code at checkout. The affiliate will receive commission for such sales too.",
    "Automatic coupons are currently not active.": "Automatic coupons are currently not active.",
    "Click here to activate them": "Click here to activate them",
    "Generate Coupons": "Generate Coupons",
    "Bulk create coupons for your affiliates": "Bulk create coupons for your affiliates",
    "Try the new bulk coupon editor": "Try the new bulk coupon editor",
    "new": "new",
    "Coupon Based Commissions": "Coupon Based Commissions",
    "Give coupons to affiliates for non referral link based promotions": "Give coupons to affiliates for non referral link based promotions",
    "Tip: You can assign coupon to affiliates.": "Tip: You can assign coupon to affiliates. Those coupons when used give commission to the affiliate for the purchase made by the customer",
    "Automatic Coupons Style": "Automatic Coupons Style",
    "The coupon code given to affiliates on sign up will try and match": "The coupon code given to affiliates on sign up will try and match",
    "Affiliate's Instagram Handle (If set during signup)": "Affiliate's Instagram Handle (If set during signup)",
    "Random letters": "Random letters",
    "Random dictionary words": "Random dictionary words",
    "Custom style": "Custom style",
    "Coupon Format": "Coupon Format",
    "Invalid coupon format": "Invalid coupon format",
    "Automatically refresh the affiliate's coupon codes after specified period": "Automatically refresh the affiliate's coupon codes after specified period. The generated coupon codes are random",
    "Dynamic Coupons": "Dynamic Coupons",
    "Automatically apply discount": "Automatically apply discount",
    "Move to ?": "Move to ?",
    "Move files": "Move files",
    "Media Category (Type to create a new one)": "Media Category (Type to create a new one)",
    "Create": "Create",
    "Short Description": "Short Description",
    "Price": "Price",
    "You get": "You get",
    "Featured Products": "Featured Products",
    "DYNAMIC": "DYNAMIC",
    "Add Extra Information": "Add Extra Information",
    "Description": "Description",
    "Product Link": "Product Link",
    "Media assets are currently disabled in affiliate's dashboard.": "Media assets are currently disabled in affiliate's dashboard. The affiliates will not able to see the creatives that you upload here",
    "Enable": "Enable",
    "Creatives are banners, logos and other media items which your affiliates can use to promote your products.": "Creatives are banners, logos and other media items which your affiliates can use to promote your products. We recommend creating unique, catchy images for better conversions",
    "Show all": "Show all",
    "Bulk Actions": "Bulk Actions",
    "Upgrade to remove all limits": "Upgrade to remove all limits",
    "Media Category (optional)": "Media Category (optional)",
    "Upload Error. Please try again": "Upload Error. Please try again",
    "Upload CSV": "Upload CSV",
    "Image Preview": "Image Preview",
    "Text": "Text",
    "Size": "Size",
    "Position X": "Position X",
    "Position Y": "Position Y",
    "Color": "Color",
    "Background": "Background",
    "Rotation": "Rotation",
    "Margin": "Margin",
    "Font Size": "Font Size",
    "Fill": "Fill",
    "Stroke": "Stroke",
    "Stroke Width": "Stroke Width",
    "Opacity": "Opacity",
    "Dynamic Images": "Dynamic Images",
    "Personalize your creatives for individual affiliates by using dynamic tags": "Personalize your creatives for individual affiliates by using dynamic tags",
    "Download Preview": "Download Preview",
    "Dynamic images are only available for premium subscribers.": "Dynamic images are only available for premium subscribers.",
    "Upgrade here": "Upgrade here",
    "Editor": "Editor",
    "Base Image": "Base Image",
    "Elements": "Elements",
    "Choose an element to add below": "Choose an element to add below",
    "Add new elements": "Add new elements",
    "See list of dynamic tags": "See list of dynamic tags",
    "Got feedback or suggestions ?": "Got feedback or suggestions ? Want more options/elements ? Please let us know",
    "Note: Use mousewheel to zoom in/out and click to hold and drag the image around": "Note: Use mousewheel to zoom in/out and click to hold and drag the image around",
    "Upload an image to start editing": "Upload an image to start editing",
    "Drag 'n' drop some files here, or click to select files": "Drag 'n' drop some files here, or click to select files",
    "QRCode": "QRCode",
    "Bulk Import": "Bulk Import",
    "Connected Customers": "Connected Customers",
    "Import your affiliate's existing customers.": "Import your affiliate's existing customers.",
    "If connection already exists": "If connection already exists",
    "Skip": "Skip",
    "Overwrite": "Overwrite",
    "Validity": "Validity",
    "The attached affiliate will get commission when the connected customer makes a purchase": "The attached affiliate will get commission when the connected customer makes a purchase",
    "Filter by customer or affiliate": "Filter by customer or affiliate",
    "New Connection": "New Connection",
    "Classic Signup Page": "Classic Signup Page",
    "Background Image": "Background Image",
    "Select From Gallery": "Select From Gallery",
    "Overlay Content": "Overlay Content",
    "Features (one per line)": "Features (one per line)",
    "Preview Page": "Preview Page",
    "Signup Field": "Signup Field",
    "Sponsor Search": "Sponsor Search",
    "Signup Page Title": "Signup Page Title",
    "Signup Fields": "Signup Fields",
    "Social Login": "Social Login",
    "Google Login": "Google Login",
    "Facebook Login": "Facebook Login",
    "Signup Button Text": "Signup Button Text",
    "Choose style for your affiliate signup page": "Choose style for your affiliate signup page",
    "Extra signup pages": "Extra signup pages",
    "Extra signup pages for your affiliate program": "Extra signup pages for your affiliate program",
    "New Signup Page": "New Signup Page",
    "Copy Page Link": "Copy Page Link",
    "Delete Page": "Delete Page",
    "Edit Settings": "Edit Settings",
    "Edit Sign-up form": "Edit Sign-up form",
    "Duplicate Page": "Duplicate Page",
    "Signup page name (for your reference)": "Signup page name (for your reference)",
    "Page URL": "Page URL",
    "Commission group": "Commission group",
    "Commission (if different from default commission)": "Commission (if different from default commission)",
    "Sign-up bonus (if different from default)": "Sign-up bonus (if different from default)",
    "Auto Approval": "Auto Approval",
    "Default settings": "Default settings",
    "Approved automatically": "Approved automatically",
    "Not approved automatically": "Not approved automatically",
    "Edit Sign up Page": "Edit Sign up Page",
    "New Sign up page": "New Sign up page",
    "Minimum Payout (if different from default)": "Minimum Payout (if different from default)",
    "Use custom welcome email": "Use custom welcome email",
    "Modern Signup Page": "Modern Signup Page",
    "Sleek Signup Page": "Sleek Signup Page",
    "Choose Signup Page style": "Choose Signup Page style",
    "Signup Page Style": "Signup Page Style",
    "Classic": "Classic",
    "Active": "Active",
    "Two column layout": "Two column layout",
    "One column for program details": "One column for program details",
    "Second column for signup form": "Second column for signup form",
    "Select & Customize": "Select & Customize",
    "Upgrade to customize": "Upgrade to customize",
    "Slim": "Slim",
    "Single centered column layout": "Single centered column layout",
    "Sleek and minimalistic design": "Sleek and minimalistic design",
    "Modern": "Modern",
    "Left column for large background image": "Left column for large background image",
    "Right column for signup form": "Right column for signup form",
    "Affiliate Toolbar": "Affiliate Toolbar",
    "Shows a toolbar on your store to the logged in affiliates for easy sharing of product links": "Shows a toolbar on your store to the logged in affiliates for easy sharing of product links",
    "Affiliate Toolbar - Customize": "Affiliate Toolbar - Customize",
    "Background Color": "Background Color",
    "Text Color": "Text Color",
    "Copy Button Text": "Copy Button Text",
    "Copied Text": "Copied Text",
    "Dashboard Text": "Dashboard Text",
    "Social Icons": "Social Icons",
    "Customize Cart Referral Code Input Field": "Customize Cart Referral Code Input Field",
    "Form Input": "Form Input",
    "Label": "Label",
    "Placeholder": "Placeholder",
    "Shopping Cart Referred By": "Shopping Cart Referred By",
    "Let customers enter the affiliate's referral code in shopping cart.": "Let customers enter the affiliate's referral code in shopping cart.",
    "See Preview": "See Preview",
    "Shopping-with Floating Widget": "Shopping-with Floating Widget",
    "Customize Shopping-with Floating Widget": "Customize Shopping-with Floating Widget",
    "Content": "Content",
    "Widget Position": "Widget Position",
    "Right": "Right",
    "Left": "Left",
    "Hide on mobile": "Hide on mobile",
    "Default avatar": "Default avatar",
    "Spacing bottom": "Spacing bottom",
    "Customize Shopping-with Toolbar": "Customize Shopping-with Toolbar",
    "Show available variables": "Show available variables",
    "Shopping-with Toolbar": "Shopping-with Toolbar",
    "Store Toolbars": "Store Toolbars",
    "Toolbars to help your customers and affiliates.": "Toolbars to help your customers and affiliates. They appear at the top your store",
    "Store Profile": "Store Profile",
    "Landing page": "Landing page",
    "Signup page": "Signup page",
    "Affiliate Dashboard": "Affiliate Dashboard",
    "Toolbars": "Toolbars",
    "Your domain name could not be verified.": "Your domain name could not be verified. Please make sure you have added the CNAME record on your domain with the following values",
    "Name": "Name",
    "Points to": "Points to",
    "Overview": "Overview",
    "Membership": "Membership",
    "Premium": "Premium",
    "Stopped": "Stopped",
    "Live": "Live",
    "Important Links": "Important Links",
    "Affiliate Portal": "Affiliate Portal",
    "Affiliate Login Page": "Affiliate Login Page",
    "Affiliate Signup Page": "Affiliate Signup Page",
    "Get better brand identity": "Get better brand identity",
    "Change affiliate portal link to": "Change affiliate portal link to",
    "New Registrations": "New Registrations",
    "Recent Orders": "Recent Orders",
    "Top Partners": "Top Partners",
    "Leave a nice feedback on the app store to keep the team motivated and bring you new features": "Leave a nice feedback on the app store to keep the team motivated and bring you new features",
    "Give Feedback": "Give Feedback",
    "Restart Setup wizard ?": "Restart Setup wizard ?",
    "Edit Translations": "Edit Translations",
    "Search for text": "Search for text",
    "This will revert any changes that you have made to the translations.": "This will revert any changes that you have made to the translations. Please confirm if you want to revert to default",
    "Save Translations": "Save Translations",
    "Cancel Unclaimed Payment": "Cancel Unclaimed Payment",
    "Cancel Payment": "Cancel Payment",
    "Cancelling the payment will return the money to your PayPal account and set the affiliate's payment as pending": "Cancelling the payment will return the money to your PayPal account and set the affiliate's payment as pending",
    "PayPal Email": "PayPal Email",
    "Amount": "Amount",
    "Wallet Password": "Wallet Password",
    "Type confirm in the field below": "Type confirm in the field below",
    "Processed": "Processed",
    "Your PayPal account has insufficient funds to process this request": "Your PayPal account has insufficient funds to process this request",
    "Your PayPal account has been restricted.": "Your PayPal account has been restricted. Contact PayPal for more information",
    "You need to be a verified PayPal account holder to send payouts.": "You need to be a verified PayPal account holder to send payouts.",
    "Your account is not authorized to use payouts.": "Your account is not authorized to use payouts. You will need to contact PayPal customer service to get Payouts API activated on your account",
    "Due to currency compliance regulations, you are not allowed to make this transaction.": "Due to currency compliance regulations, you are not allowed to make this transaction.",
    "You have insufficient funds for this currency": "You have insufficient funds for this currency",
    "Details": "Details",
    "Delete failed payout entry?": "Delete failed payout entry?",
    "Refresh Status": "Refresh Status",
    "Review and Confirm Payment": "Review and Confirm Payment",
    "Send Money": "Send Money",
    "Total Amount": "Total Amount",
    "Amount owed": "Amount owed",
    "Total Taxes": "Total Taxes",
    "Net payment": "Net payment",
    "Unlock Wallet": "Unlock Wallet",
    "Confirmation": "Confirmation",
    "type confirm to approve the payment": "type confirm to approve the payment",
    "type wallet password to pay": "type wallet password to pay",
    "Remove": "Remove",
    "Payout Details": "Payout Details",
    "Refresh": "Refresh",
    "ATTENTION": "ATTENTION",
    "ALL ITEMS": "ALL ITEMS",
    "Start Payout": "Start Payout",
    "Payment History": "Payment History",
    "Paid Via": "Paid Via",
    "Payee": "Payee",
    "Minimum Payout": "Minimum Payout",
    "Total Pending": "Total Pending",
    "Review and Confirm": "Review and Confirm",
    "Payment group title": "Payment group title",
    "Pending Amount Breakdown": "Pending Amount Breakdown",
    "Source": "Source",
    "Direct Sales": "Direct Sales",
    "Indirect Sales (MLM)": "Indirect Sales (MLM)",
    "Unlock PayPal Wallet": "Unlock PayPal Wallet",
    "Unlock PayPal wallet for this session to use the Payouts Section without": "Unlock PayPal wallet for this session to use the Payouts Section without",
    "Key": "Key",
    "Explanation": "Explanation",
    "The target identifier.": "The target identifier. It is automatically generated. This identifier is used to limit bonus to once per time period",
    "Name of the target.": "Name of the target. Can be anything. It is for your and affiliate's reference only",
    "Applies On": "Applies On",
    "On which parameter should the Minimum Value be taken.": "On which parameter should the Minimum Value be taken.",
    "Minimum Value": "Minimum Value",
    'Minimum value of the "Applies on" to reach to get the bonus': 'Minimum value of the "Applies on" to reach to get the bonus',
    "Time Period": "Time Period",
    "The time period during which the affiliate has to complete the target to receive the bonus": "The time period during which the affiliate has to complete the target to receive the bonus",
    "Bonus": "Bonus",
    "Amount given to affiliate for reaching the target in the specific time period": "Amount given to affiliate for reaching the target in the specific time period",
    "Total Affiliate Commission": "Total Affiliate Commission",
    "Total Revenue Generated": "Total Revenue Generated",
    "Total Commissionable volume": "Total Commissionable volume",
    "Total Sale commission": "Total Sale commission",
    "Total Network Commission": "Total Network Commission",
    "Bonus Structure": "Bonus Structure",
    "Silver": "Silver",
    "Gold": "Gold",
    "Platinum": "Platinum",
    "Applies on": "Applies on",
    "Target Bonus": "Target Bonus",
    "Given to affiliates on completing the set targets": "Given to affiliates on completing the set targets",
    "Signup Bonus": "Signup Bonus",
    "Given to affiliates when they register": "Given to affiliates when they register",
    "Signup Bonuses": "Signup Bonuses",
    "Delete bonus ?": "Delete bonus ?",
    "One time bonus given to reach the target": "One time bonus given to reach the target",
    "Setup Targets and Bonus": "Setup Targets and Bonus",
    "Bonus is give when the target is reached.": "Bonus is give when the target is reached. It is given once in the specified time period",
    "Hide Explanation": "Hide Explanation",
    "Show Explanation": "Show Explanation",
    "Glossary of Terms": "Glossary of Terms",
    "New Target": "New Target",
    "Boost": "Boost",
    "Boost on top of affiliate's commission provided.": "Boost on top of affiliate's commission provided. Calculated from the affiliate's commission and not order value",
    "How boosters work ?": "How boosters work ?",
    "Commission booster work on 3 principles": "Commission booster work on 3 principles",
    "They are given on Affiliate's Original Commission": "They are given on Affiliate's Original Commission",
    "They are additive": "They are additive",
    "More than one booster can be applied on commission, if the conditions are met": "More than one booster can be applied on commission, if the conditions are met",
    "They are repetitive": "They are repetitive",
    "They will be given on every order of affiliate till the conditions are met": "They will be given on every order of affiliate till the conditions are met",
    "This setup ensures that you can have a huge number of permutations and combinations available to get any desired result": "This setup ensures that you can have a huge number of permutations and combinations available to get any desired result",
    "Example": "Example",
    "Confused ?": "Confused ?",
    "Lets revisit the principles": "Lets revisit the principles",
    "They are on Affiliate's Original Commission": "They are on Affiliate's Original Commission",
    "Commission Booster": "Commission Booster",
    "Give extra commissions when affiliates cross a tier": "Give extra commissions when affiliates cross a tier",
    "Setup Commission Booster": "Setup Commission Booster",
    "Commission boosters increase the sale commission when conditions are fulfilled.": "Commission boosters increase the sale commission when conditions are fulfilled.",
    "Learn how they work and how to set them up": "Learn how they work and how to set them up",
    "Boosters": "Boosters",
    "New customers orders": "New customers orders",
    "New Boost": "New Boost",
    "during the current month": "during the current month",
    "during the current week": "during the current week",
    "in the last 30 days": "in the last 30 days",
    "in the last 90 days": "in the last 90 days",
    "in the last 60 days": "in the last 60 days",
    "in the last 1 year": "in the last 1 year",
    "throughout his lifetime": "throughout his lifetime",
    "Recurring bonus": "Recurring bonus",
    "Give a bonus to the affiliate everytime the target is reached": "Give a bonus to the affiliate everytime the target is reached",
    "Create new recurring bonus": "Create new recurring bonus",
    "No recurring bonus set": "No recurring bonus set",
    "Create recurring bonus": "Create recurring bonus",
    "Bonus Title": "Bonus Title",
    "For your reference only": "For your reference only",
    "Give bonus": "Give bonus",
    "Daily": "Daily",
    "Weekly": "Weekly",
    "Monthly": "Monthly",
    "Quarterly": "Quarterly",
    "Give first bonus at": "Give first bonus at",
    "fixed amount": "fixed amount",
    "percentage": "percentage",
    "Add a bonus": "Add a bonus",
    "Bonus qualification": "Bonus qualification",
    "In case an affiliate qualifies for more than 1 bonuses": "In case an affiliate qualifies for more than 1 bonuses",
    "Give only the highest bonus": "Give only the highest bonus",
    "Give total of all the qualified bonuses": "Give total of all the qualified bonuses",
    "Delete bonus": "Delete bonus",
    "This page is not allowed to be loaded inside an iframe": "This page is not allowed to be loaded inside an iframe",
    "Choose Platform": "Choose Platform",
    "Manage affiliates on your program": "Manage affiliates on your program",
    "Privacy Policy": "Privacy Policy",
    "Terms of Service": "Terms of Service",
    "Write Email": "Write Email",
    "Recipients": "Recipients",
    "Send to all": "Send to all",
    "Send to approved": "Send to approved",
    "Subject": "Subject",
    "To send to more than 20 affiliates in a batch, kindly setup from email for your domain.": "To send to more than 20 affiliates in a batch, kindly setup from email for your domain. This ensures your emails are sent faster and are not blocked by spam filters",
    "Preview Message": "Preview Message",
    "Email Variables": "Email Variables",
    "Affiliate's Last name": "Affiliate's Last name",
    "Affiliate's First name": "Affiliate's First name",
    "Affiliate's Email": "Affiliate's Email",
    "Affiliate's Referral code": "Affiliate's Referral code",
    "Affiliate's Coupon code": "Affiliate's Coupon code",
    "Affiliate's Referral Link": "Affiliate's Referral Link",
    "Link to the affiliate's personal dashboard": "Link to the affiliate's personal dashboard",
    "Affiliate's Company name": "Affiliate's Company name",
    "How to use them ?": "How to use them ?",
    "Place the variable where you want the data to appear.": "Place the variable where you want the data to appear.",
    "You can use them in both subject and the content of the email": "You can use them in both subject and the content of the email",
    "Quick email requires upgrade to premium subscription": "upgrade to premium subscription",
    "Quick Email": "Quick Email",
    "Quickly send email to your affiliates": "Quickly send email to your affiliates",
    "Compose Email": "Compose Email",
    "Archived": "Archived",
    "Sent": "Sent",
    "Emails": "Emails",
    "Show archived": "Show archived",
    "Un-archive": "Un-archive",
    "Archive": "Archive",
    "Monthly Earning": "Monthly Earning",
    "Active Installs": "Active Installs",
    "Total Earned": "Total Earned",
    "Total Paid Out": "Total Paid Out",
    "App Uninstalled": "App Uninstalled",
    "Referred Stores": "Referred Stores",
    "Customize Link": "Customize Link",
    "Referral Identifier": "Referral Identifier",
    "Hash": "Hash",
    "Referral Code": "Referral Code",
    "Final Link": "Final Link",
    "Your referral link": "Your referral link",
    "Copy Referral Link": "Copy Referral Link",
    "Program Status": "Program Status",
    "Turn on or off the refer program on your account": "Turn on or off the refer program on your account",
    "ON": "ON",
    "OFF": "OFF",
    "Program Commissions": "Program Commissions",
    "Subscriber Type": "Subscriber Type",
    "PREMIUM": "PREMIUM",
    "Commissions are recurring and paid every month for as long as the subscriber is active": "Commissions are recurring and paid every month for as long as the subscriber is active",
    "Payment Terms": "Payment Terms",
    "Sample Earnings Calculation": "Sample Earnings Calculation",
    "Subscribers": "Subscribers",
    "Payout": "Payout",
    "January": "January",
    "February": "February",
    "March": "March",
    "April": "April",
    "May": "May",
    "June": "June",
    "July": "July",
    "August": "August",
    "September": "September",
    "October": "October",
    "November": "November",
    "December": "December",
    "Total": "Total",
    "You will keep on making money as long as the merchants that you refer are active on the platform.": "You will keep on making money as long as the merchants that you refer are active on the platform.",
    "Paypal Address": "Paypal Address",
    "Your PayPal email address for Payouts": "Your PayPal email address for Payouts",
    "Your paypal email address": "Your paypal email address",
    "Bring other merchants to our platform and earn money": "Bring other merchants to our platform and earn money",
    "Payments": "Payments",
    "Program Info": "Program Info",
    "Be our brand ambassador": "Be our brand ambassador",
    "Refer the goaffpro app to other merchants and earn money, free upgrades etc.": "Refer the goaffpro app to other merchants and earn money, free upgrades etc.",
    "Click to Enroll": "Click to Enroll",
    "How does it work ?": "How does it work ?",
    "Enroll": "Enroll",
    "Click to enroll into the program.": "Click to enroll into the program. After enrollment, you'll be provided with a unique link to share and promote",
    "Promote": "Promote",
    "Promote the link on your facebook page, instagram, website etc.": "Promote the link on your facebook page, instagram, website etc. When a merchant installs the app using your link, you get points",
    "Redeem": "Redeem",
    "Redeem the earned points for cash or for premium upgrades to the app.": "Redeem the earned points for cash or for premium upgrades to the app. A paypal account is required for cash transfer",
    "Great going": "Great going",
    "Next thing we do is let our store visitors know of our affiliate program.": "Next thing we do is let our store visitors know of our affiliate program. For this we create a new menu entry in header or footer menu of your shop with link to your affiliate portal.",
    "Copy your affiliate portal link": "Copy your affiliate portal link",
    "Open shop's navigation menu": "Open shop's navigation menu",
    "Create the menu item in header": "Create the menu item in header",
    "I have done this already": "I have done this already",
    "I'll do this later": "I'll do this later",
    "Customize the affiliate portal": "Customize the affiliate portal",
    "Now that your affiliate's have a place to register and see their sales/earnings etc.": "Now that your affiliate's have a place to register and see their sales/earnings etc. It is important that the portal looks and feels like a part of your store",
    "Store Logo": "Store Logo",
    "Brand Color": "Brand Color",
    "Affiliate portal language": "Affiliate portal language",
    "Save and Continue": "Save and Continue",
    "Did you know ?": "Did you know ?",
    "Revenue Growth ?": "Revenue Growth ?",
    "Congratulations": "Congratulations",
    "On completing the tutorial. Now go and conquer the world": "On completing the tutorial. Now go and conquer the world",
    "Previous Slide": "Previous Slide",
    "Next Slide": "Next Slide",
    "Done": "Done",
    "Alright": "Alright",
    "Now that you have a brief understanding of what is affiliate marketing, let us setup the app for your store": "Now that you have a brief understanding of what is affiliate marketing, let us setup the app for your store",
    "Announce": "Announce",
    "Your affiliate portal is at": "Your affiliate portal is at",
    "Your role in the network ?": "Your role in the network ?",
    "You have 3 main roles in the network": "You have 3 main roles in the network",
    "Announce that you have affiliate network": "Announce that you have affiliate network",
    "Set commissions for products": "Set commissions for products",
    "Pay the affiliates for successful sales": "Pay the affiliates for successful sales",
    "Introduction": "Introduction",
    "Study the image below briefly.": "Study the image below briefly. Go to next screen to learn about each part of affiliate marketing and how they relate to goaffpro",
    "Once your affiliate start sending you sales, these sales are recorded and a payout summary is created in the Payouts section.": "Once your affiliate start sending you sales, these sales are recorded and a payout summary is created in the Payouts section. You can use PayPal or your preferred payment processor to pay your affiliates",
    "You can set commissions for your products from the Commissions option in the app sidebar.": "You can set commissions for your products from the Commissions option in the app sidebar. We recommend setting a high commission to attract more affiliates",
    "Who is an affiliate?": "Who is an affiliate?",
    "Affiliates or Partners or Influencers are persons or entities who promote your products and get commission when their promotion causes successful sales.": "Affiliates or Partners or Influencers are persons or entities who promote your products and get commission when their promotion causes successful sales. Similar to how a salesperson gets commission for sales",
    "Parts of affiliate network": "Parts of affiliate network",
    "An affiliate network has 2 main parts": "An affiliate network has 2 main parts",
    "You": "You",
    "Who is an affiliate ? See next": "Who is an affiliate ? See next",
    "What about coupons ?": "What about coupons ?",
    "Do you want to give discount coupons to your affiliates to share with their followers?": "Do you want to give discount coupons to your affiliates to share with their followers? This is super useful when you are working with Instagram followers.",
    "See how this works": "See how this works",
    "Hide help": "Hide help",
    "Discount Amount": "Discount Amount",
    "Skip for now": "Skip for now",
    "Integration steps": "Integration steps",
    "Before the app can be fully functional, you need to integrate the following code in your store.": "Before the app can be fully functional, you need to integrate the following code in your store.",
    "Once the code integration is complete, click the Get Started button to start customizing your program": "Once the code integration is complete, click the Get Started button to start customizing your program",
    "Get Started": "Get Started",
    "Success": "Success",
    "Installation was successful": "Installation was successful",
    "Let us now setup and customize the app": "Let us now setup and customize the app",
    "Congratulations!": "Congratulations!",
    "Basic setup is done. Let's recap what all happened": "Basic setup is done. Let's recap what all happened",
    "A fully branded affiliate portal is setup at": "A fully branded affiliate portal is setup at",
    "Your store visitors can find the link in your store's header/footer menu": "Your store visitors can find the link in your store's header/footer menu",
    "They can register and login in the affiliate portal": "They can register and login in the affiliate portal",
    "Test if everything is set up correctly": "Test if everything is set up correctly",
    "First things first": "First things first",
    "Let us decide on how much commission you want to give to your affiliate for a successful referral.": "Let us decide on how much commission you want to give to your affiliate for a successful referral. The commission is awarded to your affiliates whenever the send you a customer",
    "Skip Setup": "Skip Setup",
    "Basic Setup": "Basic Setup",
    "We will walk you through the basic setup of the app.": "We will walk you through the basic setup of the app. This setup only scratches the surface of what the app can do. However, it is sufficient to get a fully functional affiliate program up and running",
    "Quick Question": "Quick Question",
    "How familiar are you with affiliate marketing ?": "How familiar are you with affiliate marketing ?",
    "I am new to Affiliate Marketing": "I am new to Affiliate Marketing",
    "I know what affiliate marketing is, but I am new to this app": "I know what affiliate marketing is, but I am new to this app",
    "I have already set up this app, I want to test it now": "I have already set up this app, I want to test it now",
    "Create an affiliate account": "Create an affiliate account",
    "You don't have any affiliate yet.": "You don't have any affiliate yet. Let's create a new affiliate account. Open the affiliate sign-up page of your affiliate program and create a new account. Come back here after the account is created.",
    "Waiting for new account": "Waiting for new account",
    "Loading...": "Loading...",
    "Success! The account was successfully created": "Success! The account was successfully created",
    "Email": "Email",
    "Referral Link": "Referral Link",
    "Approve Account": "Approve Account",
    "Take a note of the referral link, we will be using this in the next step": "Take a note of the referral link, we will be using this in the next step",
    "Next Step": "Next Step",
    "Hurray!": "Hurray!",
    "The basic setup and testing of the app is complete.": "The basic setup and testing of the app is complete. But this is just scratching the surface of the app's potential. There are numerous configuration and customization options available inside, which will allow you to run the perfect affiliate program for your store",
    "Go to Dashboard": "Go to Dashboard",
    "Test Sales": "Test Sales",
    "Now purchase any product in your store.": "Now purchase any product in your store. The order should get recorded and the affiliate commission as well as order details would be displayed below",
    "Waiting for the order": "Waiting for the order",
    "Success! The sale was recorded successfully": "Success! The sale was recorded successfully",
    "Order Details": "Order Details",
    "Seems right? Next Step": "Seems right? Next Step",
    "Skip this step": "Skip this step",
    "Testing the app": "Testing the app",
    "It's a good thing to test if the app is set up properly.": "It's a good thing to test if the app is set up properly. This also allows you to understand how the app works from an affiliate's point of view",
    "For basic testing, we will do three things": "For basic testing, we will do three things",
    "Create a dummy or test affiliate account": "Create a dummy or test affiliate account",
    "Check if the affiliate's referral link is getting tracked on your store": "Check if the affiliate's referral link is getting tracked on your store",
    "Check if the orders placed are getting recorded and the affiliate commission is properly calculated": "Check if the orders placed are getting recorded and the affiliate commission is properly calculated",
    "Create a new affiliate account": "Create a new affiliate account",
    "Test Referral Link": "Test Referral Link",
    "Click on the affiliate's referral link.": "Click on the affiliate's referral link. This should take you to your store. The visit should get recorded in the app",
    "Waiting for the visit": "Waiting for the visit",
    "Success! The visit was recorded successfully": "Success! The visit was recorded successfully",
    "Visitor IP": "Visitor IP",
    "Seems correct? Let's test the referral sale now": "Seems correct? Let's test the referral sale now",
    "Browser": "Browser",
    "Device": "Device",
    "Operating System": "Operating System",
    "Pardon the interruption": "Pardon the interruption",
    "Sure, Why not": "Sure, Why not",
    "Remind me later": "Remind me later",
    "Sorry for the interruption.": "Sorry for the interruption. We'd love to hear your feedback on the app. Can you spare 2 minutes of your time and give us feedback? Your words of encouragement would mean a lot to us",
    "Your monthly revenue": "Your monthly revenue",
    "Your average profit margin per product": "Your average profit margin per product",
    "Profit Margin": "Profit Margin",
    "Number of orders placed per month": "Number of orders placed per month",
    "Without GoAffPro": "Without GoAffPro",
    "With GoAffPro": "With GoAffPro",
    "Gross Benefit": "Gross Benefit",
    "Net Benefit": "Net Benefit",
    "Gross Benefit - Difference of profits with and without GoAffPro network": "Gross Benefit - Difference of profits with and without GoAffPro network",
    "Net Benefit - Net profit difference after removing the subscription cost and partner commissions": "Net Benefit - Net profit difference after removing the subscription cost and partner commissions",
    "Header": "Header",
    "Header block for your emails": "Header block for your emails",
    "Logo": "Logo",
    "Alignment": "Alignment",
    "Divider color": "Divider color",
    "Full width banner": "Full width banner",
    "Button": "Button",
    "Click to action button": "Click to action button",
    "Font Thickness": "Font Thickness",
    "Link to": "Link to",
    "Line Spacing": "Line Spacing",
    "Email footer": "Email footer",
    "Your address": "Your address",
    "Table": "Table",
    "Rich Text": "Rich Text",
    "Use brand color for buttons": "Use brand color for buttons",
    "Corners": "Corners",
    "Align": "Align",
    "Title": "Title",
    "Link": "Link",
    "Use store logo": "Use store logo",
    "Show Divider": "Show Divider",
    "Thickness": "Thickness",
    "Use brand color for divider": "Use brand color for divider",
    "Column": "Column",
    "Add row": "Add row",
    "Subject of the email": "Subject of the email",
    "Available Variables": "Available Variables",
    "You can use the variables available below in your emails and they will be replaced with actual data when the email is sent": "You can use the variables available below in your emails and they will be replaced with actual data when the email is sent",
    "Shop": "Shop",
    "Hide available variables": "Hide available variables",
    "SMS Notification": "SMS Notification",
    "Add Section": "Add Section",
    "Reset Template": "Reset Template",
    "New affiliate registration": "New affiliate registration",
    "This email is sent to the store owner when a new affiliate registers": "This email is sent to the store owner when a new affiliate registers",
    "New affiliate sale": "New affiliate sale",
    "This email is sent to the store owner when an affiliate gets you a sale": "This email is sent to the store owner when an affiliate gets you a sale",
    "Affiliate file upload": "Affiliate file upload",
    "This email is sent to the store owner when an affiliate uploads a file in their dashboard": "This email is sent to the store owner when an affiliate uploads a file in their dashboard",
    "Affiliate contract signed": "Affiliate contract signed",
    "This email is sent to the store owner when an affiliate signs the legal contract": "This email is sent to the store owner when an affiliate signs the legal contract",
    "Affiliate W9 signed": "Affiliate W9 signed",
    "This email is sent to the store owner when the affiliate completes the W9 form": "This email is sent to the store owner when the affiliate completes the W9 form",
    "Affiliate invitation email": "Affiliate invitation email",
    "This email is sent to the affiliate when you invite them to join your program via the app": "This email is sent to the affiliate when you invite them to join your program via the app",
    "Email verification": "Email verification",
    "This email is sent to the affiliate when they register to verify their email address": "This email is sent to the affiliate when they register to verify their email address",
    "Forgot password email": "Forgot password email",
    "This email is sent to the affiliate when they request their password": "This email is sent to the affiliate when they request their password",
    "Account approved email": "Account approved email",
    "This email is sent to the affiliate when their account is approved in the program": "This email is sent to the affiliate when their account is approved in the program",
    "Account blocked": "Account blocked",
    "This email is sent to the affiliate when their account is blocked from the program": "This email is sent to the affiliate when their account is blocked from the program",
    "Account rejected": "Account rejected",
    "This email is sent to the affiliate when their new account application is rejected from the program": "This email is sent to the affiliate when their new account application is rejected from the program",
    "Account deleted email": "Account deleted email",
    "This email is sent to the affiliate when their account is deleted from the program": "This email is sent to the affiliate when their account is deleted from the program",
    "New customer order": "New customer order",
    "This email is sent to the affiliate when their customer places the order": "This email is sent to the affiliate when their customer places the order",
    "Order Updated": "Order Updated",
    "This email is sent to the affiliate when the order status is updated": "This email is sent to the affiliate when the order status is updated",
    "Payment processed": "Payment processed",
    "This email is sent to the affiliate when their payments are processed": "This email is sent to the affiliate when their payments are processed",
    "Sub-Affiliate signup": "Sub-Affiliate signup",
    "This email is sent to the affiliate when an affiliate signs up under them (Multi Level Marketing)": "This email is sent to the affiliate when an affiliate signs up under them (Multi Level Marketing)",
    "Contract Sign Request": "Contract Sign Request",
    "This email is sent to the affiliate when the merchant sends them the contract to sign": "This email is sent to the affiliate when the merchant sends them the contract to sign",
    "Admin notifications": "Admin notifications",
    "Affiliate notifications": "Affiliate notifications",
    "Custom notifications": "Custom notifications",
    "OLD TEMPLATE": "OLD TEMPLATE",
    "Notification title": "Notification title",
    "Notification text": "Notification text",
    "Notification Image (optional)": "Notification Image (optional)",
    "Remove block": "Remove block",
    "Send products to your affiliates and track their status seamlessly from a single dashboard": "Send products to your affiliates and track their status seamlessly from a single dashboard",
    "Create new order": "Create new order",
    "Shipping Address": "Shipping Address",
    "No order created": "No order created",
    "Customer": "Customer",
    "Shipping to": "Shipping to",
    "Fulfillment status": "Fulfillment status",
    "Shipping status": "Shipping status",
    "Copy Link": "Copy Link",
    "Open Link": "Open Link",
    "Create Order": "Create Order",
    "Choose Affiliate": "Choose Affiliate",
    "Quantity": "Quantity",
    "Add product": "Add product",
    "Select Product": "Select Product",
    "Order note (optional)": "Order note (optional)",
    "Order tags (optional)": "Order tags (optional)",
    "Order Summary": "Order Summary",
    "Ship to": "Ship to",
    "No products selected": "No products selected",
    "Address Line 1": "Address Line 1",
    "Address Line 2": "Address Line 2",
    "City": "City",
    "Province": "Province",
    "Country": "Country",
    "Postal Code": "Postal Code",
    "Login to your Store Manager": "Login to your Store Manager",
    "Using the left hand navigation, go to Content >Header and Footer": "Using the left hand navigation, go to Content >Header and Footer",
    "Add the following code to the Global Footer field with the WYSIWYG mode turned off.": "Add the following code to the Global Footer field with the WYSIWYG mode turned off.",
    "Log into your Store Manager": "Log into your Store Manager",
    "Go to Content >Titles & Content": "Go to Content >Titles & Content",
    'Look for "Checkout #4" and click its "Actions >Edit" button located to the far right of the page listing': 'Look for "Checkout #4" and click its "Actions >Edit" button located to the far right of the page listing',
    "Add the following code to the Footer section": "Add the following code to the Footer section",
    "Just a moment": "Just a moment",
    "Open BigCommerce Admin Page": "Open BigCommerce Admin Page",
    "Open Page": "Open Page",
    "Unable to load the app, kindly reinstall": "Unable to load the app, kindly reinstall",
    "Manually add tracking code": "Manually add tracking code",
    "Select Advanced Settings -> Web Analytics in the Sidebar": "Select Advanced Settings -> Web Analytics in the Sidebar",
    "Paste the code given below in the Affiliate Conversion Tracking tab": "Paste the code given below in the Affiliate Conversion Tracking tab",
    "Back to home": "Back to home",
    "Create store account": "Create store account",
    "Admin account": "Admin account",
    "API Keys": "API Keys",
    "Products - Read only": "Products - Read only",
    "Collections - Read only": "Collections - Read only",
    "Customers - Read only": "Customers - Read only",
    "Orders - Read only": "Orders - Read only",
    "API KEY": "API KEY",
    "API Password": "API Password",
    "Store": "Store",
    "Create account": "Create account",
    "Login to your Commerce HQ admin panel": "Login to your Commerce HQ admin panel",
    "Integrate Goaffpro with your custom store": "Integrate Goaffpro with your custom store",
    "Store name": "Store name",
    "Store URL": "Store URL",
    "Integration instructions": "Integration instructions",
    "Step 1": "Step 1",
    "Step 2": "Step 2",
    "Need more information ? Read our complete sdk setup guide here": "Need more information ? Read our complete sdk setup guide herecomplete sdk setup guide here",
    "Please wait": "Please wait",
    "Store Setup": "Store Setup",
    "Scroll down to API Access and Copy paste the Keys below": "Scroll down to API Access and Copy paste the Keys below",
    "API Key": "API Key",
    "API Secret": "API Secret",
    "Your Website": "Your Website",
    "Your Website URL": "Your Website URL",
    "Squarespace API Key": "Squarespace API Key",
    "Open Squarespace Site Admin": "Open Squarespace Site Admin",
    "Copy the generated API KEY and paste it below": "Copy the generated API KEY and paste it below",
    "Login to your Squarespace admin panel": "Login to your Squarespace admin panel",
    "The checkout was successfully connected": "The checkout was successfully connected",
    "Go to dashboard": "Go to dashboard",
    "Error in connecting the square checkout.": "Error in connecting the square checkout.",
    "Try again": "Try again",
    "Choose website": "Choose website",
    "Currency": "Currency",
    "Integrate Goaffpro with Webflow store": "Integrate Goaffpro with Webflow store",
    "Login to your Webflow admin panel": "Login to your Webflow admin panel",
    "Open your Weebly/Square store backoffice/admin and follow the steps below": "Open your Weebly/Square store backoffice/admin and follow the steps below",
    "Paste the following in the Custom code field": "Paste the following in the Custom code field",
    "Choose your platform": "Choose your platform",
    "Add another card": "Add another card",
    "Form Name": "Form Name",
    "Name of this form (for internal use)": "Name of this form (for internal use)",
    "Form Selector": "Form Selector",
    "The CSS Selector to find the form in your store": "The CSS Selector to find the form in your store",
    "Selector": "Selector",
    "Form Fields": "Form Fields",
    "The CSS Selectors to capture the form fields": "The CSS Selectors to capture the form fields",
    "Add a field": "Add a field",
    "Delete this form ?": "Delete this form ?",
    "Form Referrals": "Form Referrals",
    "Capture form data and attribute the lead to the affiliate": "Capture form data and attribute the lead to the affiliate",
    "Setup form": "Setup form",
    "New form": "New form",
    "You'll be able to configure the form in the next step": "You'll be able to configure the form in the next step",
    "Lead": "Lead",
    "Total Leads:": "Total Leads:",
    "Install": "Install",
    "Store Domain": "Store Domain",
    "Install App": "Install App",
    "Open your wooCommerce admin": "Open your wooCommerce admin",
    "Install the plugin": "Install the plugin",
    "Forgot password": "Forgot password",
    "Login": "Login",
    "You have been logged out of the app": "You have been logged out of the app",
    "Manager's Email Address": "Manager's Email Address",
    "Manager's Password": "Manager's Password",
    "Limit manager access": "Limit manager access",
    "Limit Access to": "Limit Access to",
    "Manager accounts": "Manager accounts",
    "Give external access for the admin interface to your program manager": "Give external access for the admin interface to your program manager",
    "New Manager Account": "New Manager Account",
    "Manager Accounts": "Manager Accounts",
    "Login Page": "Login Page",
    "Permissions": "Permissions",
    "Full Access": "Full Access",
    "Copy Login Details": "Copy Login Details",
    "Delete Account": "Delete Account",
    "Manager Login": "Manager Login",
    "Admin Login": "Admin Login",
    "Username or Email address": "Username or Email address",
    "New year celebration": "New year celebration",
    "Customize email template": "Customize email template",
    "Choose affiliates to send the email to": "Choose affiliates to send the email to",
    "Step 3": "Step 3",
    "Send the email": "Send the email",
    "Marketing": "Marketing",
    "Send marketing mailers and push notifications to your affiliates": "Send marketing mailers and push notifications to your affiliates",
    "New campaign": "New campaign",
    "Listing": "Listing",
    "Statistics": "Statistics",
    "Goaffpro Marketplace": "Goaffpro Marketplace",
    "Icons and Logo": "Icons and Logo",
    "Store Icon": "Store Icon",
    "Listing Details": "Listing Details",
    "Details about your store": "Details about your store",
    "Store Category (maximum 5)": "Store Category (maximum 5)",
    "Store Description": "Store Description",
    "Write about what your store, products and brand.": "Write about what your store, products and brand.",
    "Geo Restriction": "Geo Restriction",
    "Restrict affiliate from applying based on their country.": "Restrict affiliate from applying based on their country. Leave empty to allow affiliates from all countries",
    "Only allow affiliates from": "Only allow affiliates from",
    "Product Index": "Product Index",
    "Allow for affiliates to search for your products": "Allow for affiliates to search for your products",
    "Quick Enroll": "Quick Enroll",
    "Allow for affiliates to enroll to your program with one click": "Allow for affiliates to enroll to your program with one click",
    "Mobile App for Affiliates": "Mobile App for Affiliates",
    "Let your affiliates manage their links, earnings, payouts through native mobile apps": "Let your affiliates manage their links, earnings, payouts through native mobile apps",
    "Notifications": "Notifications",
    "Send a quick notification to your affiliates": "Send a quick notification to your affiliates",
    "Download the app from": "Download the app from",
    "Google Play store": "Google Play store",
    "Show QR Code": "Show QR Code",
    "iOS App": "iOS App",
    "Login if you already have an affiliate account or create a new account in the app": "Login if you already have an affiliate account or create a new account in the app",
    "When prompted enter the following STORE ID (or scan the QR code below)": "When prompted enter the following STORE ID (or scan the QR code below)",
    "Are mobile apps part of FREE plan ?": "Are mobile apps part of FREE plan ?",
    "Yes. Mobile apps are and will stay in the FREE plan": "Yes. Mobile apps are and will stay in the FREE plan",
    "Do they cost anything to install ?": "Do they cost anything to install ?",
    "The mobile apps are FREE to install and will stay FREE forever": "The mobile apps are FREE to install and will stay FREE forever",
    "Advantages of Mobile App": "Advantages of Mobile App",
    "Higher affiliate engagement": "Higher affiliate engagement",
    "Mobile apps have a higher engagement rate then websites.": "Mobile apps have a higher engagement rate then websites. Higher engagements of affiliate would lead to more sharing and thereby greater revenues for you",
    "Faster sharing of links": "Faster sharing of links",
    "One-Click sharing integration with facebook/instagram etc. allows for a hassle free sharing of links by the affiliates": "One-Click sharing integration with facebook/instagram etc. allows for a hassle free sharing of links by the affiliates",
    "QRCode based offline sales": "QRCode based offline sales",
    "Affiliates can quickly share the links with prospective customers offline using the QR Code feature.": "Affiliates can quickly share the links with prospective customers offline using the QR Code feature.",
    "QRCode based multi-level recruitment": "QRCode based multi-level recruitment",
    "Affiliates can recruit sub affiliates offline with QR Code network building feature.": "Affiliates can recruit sub affiliates offline with QR Code network building feature.",
    "Push Notifications": "Push Notifications",
    "With push notifications affiliates stay on top their sales activity.": "With push notifications affiliates stay on top their sales activity. You can also send custom notifications to keep the affiliate's engaged",
    "Level": "Level",
    "sale commission": "sale commission",
    "fixed commission": "fixed commission",
    "partner below sale level": "partner below sale level",
    "Multi Level Marketing": "Multi Level Marketing",
    "Affiliates can have sub affiliates and they all get commission when a referred sale is made down the level": "Affiliates can have sub affiliates and they all get commission when a referred sale is made down the level",
    "See Geneology (Full Network)": "See Geneology (Full Network)",
    "Levels and Commissions": "Levels and Commissions",
    "Network": "Network",
    "Possible recursion error.": "Possible recursion error. Kindly notify us at admin@goaffpro.com",
    "Playground": "Playground",
    "Change parameters to see how a MLM network works": "Change parameters to see how a MLM network works",
    "Number of partners in the chain": "Number of partners in the chain",
    "Chain of partners is alphabetical.": "Chain of partners is alphabetical. eg. Partner A referred Partner B, Partner B referred Partner C etc.",
    "Who referred the sale": "Who referred the sale",
    "Commission Calculation Method": "Commission Calculation Method",
    "Dynamic": "Dynamic",
    "Simple": "Simple",
    "Order Value": "Order Value",
    "Commission for sale": "Commission for sale",
    "Your setup MLM commissions": "Your setup MLM commissions",
    "Setup Commission Level": "Setup Commission Level",
    "Setup levels/tiers for your affiliate network": "Setup levels/tiers for your affiliate network",
    "Number of levels": "Number of levels",
    "Levels Disabled": "Levels Disabled",
    "Levels": "Levels",
    "Commission per level": "Commission per level",
    "Setup number of commission for levels.": "Setup number of commission for levels. The length of downlines/chain can be infinite. The commissions are limited to 10 levels. To increase the limit, contact support",
    "Test and Learn how your MLM network will work": "Test and Learn how your MLM network will work",
    "Open Playground": "Open Playground",
    "Configuration options for MLM network": "Configuration options for MLM network",
    "Show Sponsor on Signup Page": "Show Sponsor on Signup Page",
    "Show who referred the affiliate on create-account page": "Show who referred the affiliate on create-account page",
    "Level Commission Calculation Method": "Level Commission Calculation Method",
    "Level commission calculated as percentage of the sale commission": "Level commission calculated as percentage of the sale commission",
    "Level commission calculated as percentage of order value": "Level commission calculated as percentage of order value",
    "Apply Level Commission even when sale commission is nil": "Apply Level Commission even when sale commission is nil",
    "MLM Availability for Affiliates": "MLM Availability for Affiliates",
    "Enabled by default": "Enabled by default",
    "Disabled by default": "Disabled by default",
    "MLM Network functionality will be disabled for all affiliates.": "MLM Network functionality will be disabled for all affiliates. To turn it on for an affiliate, toggle this in their settings tab.",
    "See how to enable this": "See how to enable this",
    "MLM Network functionality will be enabled for all affiliates (unless toggled off in their settings)": "MLM Network functionality will be enabled for all affiliates (unless toggled off in their settings)",
    "See where to find this setting": "See where to find this setting",
    "Set downlines as customers of affiliate": "Set downlines as customers of affiliate",
    "Gives Uplines/Parents sale commission on downlines/children's personal purchases": "Gives Uplines/Parents sale commission on downlines/children's personal purchases",
    "Gives bonus to the affiliate when they recruit an affiliate under them.": "Gives bonus to the affiliate when they recruit an affiliate under them. Works best with Membership Fees option",
    "Also give upline commission on royalties orders": "Also give upline commission on royalties orders",
    "Chosen Plan": "Chosen Plan",
    "Period": "Period",
    "1 month": "1 month",
    "Discount coupon ?": "Discount coupon ?",
    "Discount": "Discount",
    "Upgrading Plan": "Upgrading Plan",
    "Is affiliate marketing for me ?": "Is affiliate marketing for me ?",
    "Over the last 12 years, we have helped ecommerce companies grow from zero to multi million dollars in annual revenue.": "Over the last 12 years, we have helped ecommerce companies grow from zero to multi million dollars in annual revenue. Based on our experience and data collected over the years, we have created a simple to estimate how much you stand to gain from this tool",
    "Choose your upgrade method": "Choose your upgrade method",
    "Premium Plan": "Premium Plan",
    "Upgrade for less than the price of your morning coffee": "Upgrade for less than the price of your morning coffee",
    "Special 50% discount for you - Expires tonight": "Special 50% discount for you - Expires tonight",
    "Start with 15 day free trial of premium subscription.": "Start with 15 day free trial of premium subscription. You will be charged only when the trial ends. Upgrade now to lock this price forever",
    "Start Free Trial": "Start Free Trial",
    "Upgrade App": "Upgrade App",
    "Premium Features": "Premium Features",
    "Adds MLM layer to your affiliate network.": "Adds MLM layer to your affiliate network. Now you can reward your affiliates for bringing in sub affiliates",
    "Custom Domain": "Custom Domain",
    "Send emails/newsletters to your affiliates directly from within the app.": "Send emails/newsletters to your affiliates directly from within the app. Tell them about new products, keep them engaged and they will send you customers",
    "Assign targets to affiliates to complete and reward them with bonus or higher commission rates": "Assign targets to affiliates to complete and reward them with bonus or higher commission rates",
    "Get additional 30+ customization options like 2 new styles of Signup Pages, More Analytics widgets for your affiliates etc": "Get additional 30+ customization options like 2 new styles of Signup Pages, More Analytics widgets for your affiliates etc",
    "Unlocks advanced type of post checkout popup to automatically signup customers as affiliate.": "Unlocks advanced type of post checkout popup to automatically signup customers as affiliate.",
    "Why go premium ?": "Why go premium ?",
    "If you are serious about your business, you must go premium.": "If you are serious about your business, you must go premium. Going premium gives you access to some of the most powerful tools for managing your affiliate programs. You also get more branding options and dedicated servers for your affiliate program.",
    "What are my payment options ?": "What are my payment options ?",
    "You will billed monthly.": "You will billed monthly. If at any time you wish to discontinue your premium subscription or you close your store, your monthly billing will stop and your plan will be downgraded",
    "What happens when I cancel my plan": "What happens when I cancel my plan",
    "If at any time you cancel your plan, the premium features will be disabled, however data will remain intact.": "If at any time you cancel your plan, the premium features will be disabled, however data will remain intact. So in future you can pick up where you left off.",
    "Are there any other charges ?": "Are there any other charges ?",
    "No. What you see is all inclusive.": "No. What you see is all inclusive.",
    "Choose your billing cycle": "Choose your billing cycle",
    "Monthly Billing": "Monthly Billing",
    "Annual Billing": "Annual Billing",
    "16% discount": "16% discount",
    "50% off": "50% off",
    "Allow your affiliates to host showcases/party links.": "Allow your affiliates to host showcases/party links.",
    "Add order to showcase": "Add order to showcase",
    "3 showcases active": "3 showcases active",
    "Showcase configuration": "Showcase configuration",
    "Expiration": "Expiration",
    "Expiration Type": "Expiration Type",
    "Flexible": "Flexible",
    "Minimum period": "Minimum period",
    "Maximum period": "Maximum period",
    "Expires in": "Expires in",
    "Rewards": "Rewards",
    "Host's commission": "Host's commission",
    "Customer's reward": "Customer's reward",
    "Give reward as store discount coupon": "Give reward as store discount coupon",
    "Copy customer link": "Copy customer link",
    "Started": "Started",
    "Total orders": "Total orders",
    "Total volume": "Total volume",
    "Affiliate commission @ 10%": "Affiliate commission @ 10%",
    "Customer reward @ 15%": "Customer reward @ 15%",
    "Edit Script": "Edit Script",
    "New Script": "New Script",
    "Save Script": "Save Script",
    "Input": "Input",
    "Code": "Code",
    "Template": "Template",
    "Raw Output": "Raw Output",
    "Formatted Output": "Formatted Output",
    "Output Template (supports mustache templating)": "Output Template (supports mustache templating)",
    "Delete Script ?": "Delete Script ?",
    "Delete Script": "Delete Script",
    "Run Scripts": "Run Scripts",
    "Select Script": "Select Script",
    "Run Script": "Run Script",
    "Assign Sale to Affiliate": "Assign Sale to Affiliate",
    "Assign Order": "Assign Order",
    "Bulk Import Orders": "Bulk Import Orders",
    "Try the new import tool": "Try the new import tool",
    "See glossary of headings": "See glossary of headings",
    "Choose CSV File": "Choose CSV File",
    "Order number": "Order number",
    "Order ID": "Order ID",
    "Affiliate Email": "Affiliate Email",
    "Order number as shown in your store dashboard": "Order number as shown in your store dashboard",
    "Order ID, can be found in your order export from admin dashboard. Not required": "Order ID, can be found in your order export from admin dashboard. Not required",
    "Email address of the affiliate to credit this order to.": "Email address of the affiliate to credit this order to. The affiliate must be registered on your store",
    "Commission to give to affiliate for this sale": "Commission to give to affiliate for this sale",
    "Date when this order was made.": "Date when this order was made. If not mentioned, the current date is set",
    "Required fields": "Required fields",
    "Go to Sales": "Go to Sales",
    "Retry Import": "Retry Import",
    "Start Importing": "Start Importing",
    "Order successfully added": "Order successfully added",
    "Mark orders as paid": "Mark orders as paid",
    "It is recommended that you mark the orders as paid once you are done with payouts, so that any adjustment in the orders (a refund, cancellation), creates a new entry to ensure an audit trail can be maintained": "It is recommended that you mark the orders as paid once you are done with payouts, so that any adjustment in the orders (a refund, cancellation), creates a new entry to ensure an audit trail can be maintained",
    "Search for orders you want to mark as paid. This action will show you the orders which will be marked as paid": "Search for orders you want to mark as paid. This action will show you the orders which will be marked as paid",
    "Search by last order number": "Search by last order number",
    "Search by last order date": "Search by last order date",
    "Review the orders being marked as paid": "Review the orders being marked as paid",
    "Mark as paid": "Mark as paid",
    "Pending Approval": "Pending Approval",
    "All Sales": "All Sales",
    "Reports": "Reports",
    "Order Total": "Order Total",
    "Order Subtotal": "Order Subtotal",
    "Royalties": "Royalties",
    "Conversion Source": "Conversion Source",
    "MLM Commission": "MLM Commission",
    "Summary": "Summary",
    "Download report": "Download report",
    "No new sales": "No new sales",
    "Sales made via your affiliate's referrals appear here.": "Sales made via your affiliate's referrals appear here. You can manually approve or reject those sales and give commissions to your affiliates accordingly",
    "Fixed Commission": "Fixed Commission",
    "Shipping": "Shipping",
    "Discount Adjustment": "Discount Adjustment",
    "Commission Boost": "Commission Boost",
    "Merchant Adjustment": "Merchant Adjustment",
    "To decrease use negative value": "To decrease use negative value",
    "Total Commission": "Total Commission",
    "ORDER REJECTED": "ORDER REJECTED",
    "Published": "Published",
    "Pending": "Pending",
    "Edit Page": "Edit Page",
    "New Page": "New Page",
    "Preview to Publish": "Preview to Publish",
    "Page Preview": "Page Preview",
    "Page title": "Page title",
    "For Affiliate": "For Affiliate",
    "SEO - Meta Keywords": "SEO - Meta Keywords",
    "SEO Meta Description": "SEO Meta Description",
    "Require pages to be reviewed before publishing": "Require pages to be reviewed before publishing",
    "Notify me when page gets published": "Notify me when page gets published",
    "Notify affiliate when page gets published": "Notify affiliate when page gets published",
    "Affiliate's Pages": "Affiliate's Pages",
    "Pending Review": "Pending Review",
    "All pages": "All pages",
    "No pages pending for your approval": "No pages pending for your approval",
    "Invoices/bills for the app's service": "Invoices/bills for the app's service",
    "Unpaid Bills": "Unpaid Bills",
    "Billing Period": "Billing Period",
    "Billable Usage": "Billable Usage",
    "Bill Amount": "Bill Amount",
    "No unpaid invoices": "No unpaid invoices",
    "Paid Bills": "Paid Bills",
    "Pay now": "Pay now",
    "Paid": "Paid",
    "Current bill": "Current bill",
    "Billed Usage": "Billed Usage",
    "Billed amount": "Billed amount",
    "Group": "Group",
    "Override": "Override",
    "Remove entry from the list ?": "Remove entry from the list ?",
    "Product purchase auto enroll": "Product purchase auto enroll",
    "Automatically signup customer as an affiliate on purchase of a product and/or update their group on purchase of products": "Automatically signup customer as an affiliate on purchase of a product and/or update their group on purchase of products",
    "Add new rule": "Add new rule",
    "Product-Group enrollment": "Product-Group enrollment",
    "Any variation": "Any variation",
    "then sign up him as an affiliate and enroll him in": "then sign up him as an affiliate and enroll him in",
    "Do nothing": "Do nothing",
    "Put them in the new group": "Put them in the new group",
    "Apply on": "Apply on",
    "Collections": "Collections",
    "All variations": "All variations",
    "Commission rules": "Commission rules",
    "Rules which determine the amount of commission given for the sale": "Rules which determine the amount of commission given for the sale",
    "New Commission Rule": "New Commission Rule",
    "Customer is first time customer": "Customer is first time customer",
    "Customer is old customer/recurring customer": "Customer is old customer/recurring customer",
    "Discount Percentage": "Discount Percentage",
    "Is less than or equals to": "Is less than or equals to",
    "Is more than or equals to": "Is more than or equals to",
    "Is less than": "Is less than",
    "Is more than": "Is more than",
    "Final Percentage": "Final Percentage",
    "Add new product commission": "Add new product commission",
    "Commission booster": "Commission booster",
    "Commission modifiers": "Commission modifiers",
    "Modify affiliate commission based on the set rules. Intended for advanced users only": "Modify affiliate commission based on the set rules. Intended for advanced users only",
    "Group Configuration": "Group Configuration",
    "Configuration specific for members of this group": "Configuration specific for members of this group",
    "Default referral link": "Default referral link",
    "Default network link": "Default network link",
    "Available payment methods": "Available payment methods",
    "This overrides the available payment methods for this group of affiliates.": "This overrides the available payment methods for this group of affiliates. Keep the field empty to use the default settings",
    "Available Media Assets": "Available Media Assets",
    "This overrides the available media assets for this group of affiliates.": "This overrides the available media assets for this group of affiliates. Keep the field empty to make all media assets available to them",
    "Group badge": "Group badge",
    "Give commission for new customers": "Give commission for new customers",
    "Use system settings": "Use system settings",
    "All customers": "All customers",
    "Only new customers": "Only new customers",
    "Group manager": "Group manager",
    "Group manager has access to the affiliate's link, coupon codes, sales etc.": "Group manager has access to the affiliate's link, coupon codes, sales etc.",
    "Dashboard custom pages": "Dashboard custom pages",
    "Customer-affiliate connection": "Customer-affiliate connection",
    "Automatically connect customer with affiliate": "Automatically connect customer with affiliate",
    "Set connection expiration ?": "Set connection expiration ?",
    "Connection expires after": "Connection expires after",
    "Group Name": "Group Name",
    "Name of this group": "Name of this group",
    "Target bonus": "Target bonus",
    "Give affiliates a bonus on completing a target": "Give affiliates a bonus on completing a target",
    "Delete this target ?": "Delete this target ?",
    "New target": "New target",
    "Edit target bonus": "Edit target bonus",
    "New target bonus": "New target bonus",
    "Name of this target.": "Name of this target. For your reference only",
    "Target": "Target",
    "The target that the affiliate": "The target that the affiliate",
    "Revenue (subtotal)": "Revenue (subtotal)",
    "Sale Commission": "Sale Commission",
    "Network Commission (MLM)": "Network Commission (MLM)",
    "The amount of bonus that affiliate gets on completing this target": "The amount of bonus that affiliate gets on completing this target",
    "Multi Level Commission": "Multi Level Commission",
    "Multi level commission structure": "Multi level commission structure",
    "MLM disabled": "MLM disabled",
    "Calculate commission on": "Calculate commission on",
    "Order amount": "Order amount",
    "Downline's commission": "Downline's commission",
    "MLM Default commission": "MLM Default commission",
    "Standard commission rates for uplines": "Standard commission rates for uplines",
    "MLM Product commissions": "MLM Product commissions",
    "Product specific commission rates for uplines.": "Product specific commission rates for uplines. Overrides the default MLM commission",
    "Item": "Item",
    "Product Commission": "Product Commission",
    "Special commission which is applied on products/collections.": "Special commission which is applied on products/collections. Overrides the default commission",
    "New product commission": "New product commission",
    "Sync Collections": "Sync Collections",
    "Applies to all products (except the products/collections set below)": "Applies to all products (except the products/collections set below)",
    "Flat rate (per product)": "Flat rate (per product)",
    "Fixed amount on entire order": "Fixed amount on entire order",
    "Are you sure you want to delete this group ?": "Are you sure you want to delete this group ?",
    "Delete Group": "Delete Group",
    "Create a copy of this group?": "Create a copy of this group?",
    "Create a copy": "Create a copy",
    "New group": "New group",
    "You'll be able to set product, multi-level or commission modifiers after you save this group": "You'll be able to set product, multi-level or commission modifiers after you save this group",
    "Set Default Group": "Set Default Group",
    "Groups helps you better manage your affiliates by grouping them and bulk editing their commissions": "Groups helps you better manage your affiliates by grouping them and bulk editing their commissions",
    "Default affiliate group": "Default affiliate group",
    "Set a group as default group for new sign ups.": "Set a group as default group for new sign ups. New sign ups in your affiliate program would be automatically put in that group",
    "DEFAULT": "DEFAULT",
    "Standard Commission": "Standard Commission",
    "Special Commissions": "Special Commissions",
    "Members": "Members",
    "Edit Members": "Edit Members",
    "Add/Edit members": "Add/Edit members",
    "Set members in this group": "Set members in this group",
    "Only show affiliates who are not in any group": "Only show affiliates who are not in any group",
    "Only show approved affiliates": "Only show approved affiliates",
    "Your Bank": "Your Bank",
    "The bank processing this file": "The bank processing this file",
    "Your bank account number": "Your bank account number",
    "Your (sender) bank account number": "Your (sender) bank account number",
    "Your BSB": "Your BSB",
    "Your (sender) bank account BSB": "Your (sender) bank account BSB",
    "User": "User",
    "Your (money sender) company name": "Your (money sender) company name",
    "User Number": "User Number",
    "Your ID in the bank system. Consult with your bank": "Your ID in the bank system. Consult with your bank",
    "Description of the transactions within the file": "Description of the transactions within the file",
    "Give Discount Code": "Give Discount Code",
    "Create Gift Card": "Create Gift Card",
    "Home": "Home",
    "Profile": "Profile",
    "Messages": "Messages",
    "Confirm Deletion": "Confirm Deletion",
    "Are you sure you want to delete this payment entry.": "Are you sure you want to delete this payment entry. Please note this action cannot be undone. It is recommended to delete payment entries only in events of transaction failures",
    "Payment Details": "Payment Details",
    "Download Invoice": "Download Invoice",
    "Payment Requests": "Payment Requests",
    "Pending Payments": "Pending Payments",
    "Create Payment Note": "Create Payment Note",
    "Create Payment": "Create Payment",
    "Amount to pay": "Amount to pay",
    "Payment Note (optional)": "Payment Note (optional)",
    "Partner Message (optional)": "Partner Message (optional)",
    "Payment note or transaction ID for your reference only": "Payment note or transaction ID for your reference only",
    "Message sent to the partner with regards to payment.": "Message sent to the partner with regards to payment. You may include transaction details in the message",
    "Mark payments as paid": "Mark payments as paid",
    "Useful if you have done bulk payments and want to mark those payments as paid": "Useful if you have done bulk payments and want to mark those payments as paid",
    "Hide affiliates with no payment method": "Hide affiliates with no payment method",
    "Hide affiliates with no payment data": "Hide affiliates with no payment data",
    "Total selected": "Total selected",
    "Total payouts": "Total payouts",
    "Mark Paid": "Mark Paid",
    "Payment note": "Payment note",
    "Partner message": "Partner message",
    "Set": "Set",
    "Invoice": "Invoice",
    "Delete this payout entry?": "Delete this payout entry?",
    "PayPal Payouts": "PayPal Payouts",
    "Advanced Popup": "Advanced Popup",
    "Other Configuration": "Other Configuration",
    "Close on Escape button": "Close on Escape button",
    "Close on Background click": "Close on Background click",
    "Show only once per customer": "Show only once per customer",
    "Embed in the page (instead of popup)": "Embed in the page (instead of popup)",
    "Extra CSS": "Extra CSS",
    "Signup bonus": "Signup bonus",
    "Show only on products": "Show only on products",
    "Show only if order total is greater than": "Show only if order total is greater than",
    "Put affiliate in commission group": "Put affiliate in commission group",
    "Show Available Variables": "Show Available Variables",
    "Basic Popup": "Basic Popup",
    "Image click": "Image click",
    "When clicked open": "When clicked open",
    "Open in": "Open in",
    "In a new tab": "In a new tab",
    "In the same tab": "In the same tab",
    "Choose popup image": "Choose popup image",
    "Show popup after": "Show popup after",
    "Set button as Opt-In button": "Set button as Opt-In button",
    "Customers will only be enrolled if they click on this button": "Customers will only be enrolled if they click on this button",
    "Double-opt in button title": "Double-opt in button title",
    "Button Title": "Button Title",
    "Click opens": "Click opens",
    "Same Tab": "Same Tab",
    "New Tab": "New Tab",
    "Activate Popup": "Activate Popup",
    "Header Image": "Header Image",
    "Image Width": "Image Width",
    "Image Height": "Image Height",
    "Show": "Show",
    "Copy button text": "Copy button text",
    "Subtitle": "Subtitle",
    "Sharing Options": "Sharing Options",
    "Sharing Message": "Sharing Message",
    "Choose popup style": "Choose popup style",
    "Basic": "Basic",
    "Just an image that points to your affiliate portal": "Just an image that points to your affiliate portal",
    "Easily customizable": "Easily customizable",
    "Elements Include": "Elements Include",
    "Action Button": "Action Button",
    "Automatically signs up customer as an affiliate (optionally with double opt-in)": "Automatically signs up customer as an affiliate (optionally with double opt-in)",
    "Sends account welcome and invitation email with referral link": "Sends account welcome and invitation email with referral link",
    "Shows their referral link (and coupon code)": "Shows their referral link (and coupon code)",
    "Let your customers know of your affiliate program.": "Let your customers know of your affiliate program. Popup appears after checkout on the order status page",
    'You have set to "Disable commission if customer is an affiliate".': 'You have set to "Disable commission if customer is an affiliate". This option is not compatible with Advanced type of popup.',
    "Turn off disable commission if customer is an affiliate option": "Turn off disable commission if customer is an affiliate option",
    "Post checkout popup": "Post checkout popup",
    "This popup is shown to your customers after they have completed their checkout i.e. on the Order Status Page.": "This popup is shown to your customers after they have completed their checkout i.e. on the Order Status Page. The popup gives the customer an opportunity to join your affiliate program. The customer already knows about your products. So there is a greater chance of him promoting them",
    "Coupons page": "Coupons page",
    "The next registration would have the sequence": "The next registration would have the sequence",
    "Referral Code Style": "Referral Code Style",
    "Dictionary words": "Dictionary words",
    "Referral code length should be more than 2": "Referral code length should be more than 2",
    "Referral Code Format": "Referral Code Format",
    "Referral Link Parameters": "Referral Link Parameters",
    "Extra fields": "Extra fields",
    "Adds extra fields in the affiliate's admin page.": "Adds extra fields in the affiliate's admin page. For storing data related to ERP or for API purposes",
    "No extra fields": "No extra fields",
    "AMP Pages tracking": "AMP Pages tracking",
    "Use the guide to set tracking in your AMP pages of your store": "Use the guide to set tracking in your AMP pages of your store",
    "Add the following code to the head of the page": "Add the following code to the head of the page",
    "Add the following code to the bottom of the body of the page": "Add the following code to the bottom of the body of the page",
    "Need help adding tracking on AMP pages.": "Need help adding tracking on AMP pages. Get in touch",
    "Access Tokens": "Access Tokens",
    "New API Key": "New API Key",
    "Delete this API key ? This action is irreversible": "Delete this API key ? This action is irreversible",
    "Auto approve affiliates from": "Auto approve affiliates from",
    "Auto approve affiliates coming from utm_source (enter one source per line)": "Auto approve affiliates coming from utm_source (enter one source per line)",
    "Auto Ref linking": "Auto Ref linking",
    "Automatically adds referral code to outgoing links on your store": "Automatically adds referral code to outgoing links on your store",
    "Generate Shortened Links": "Generate Shortened Links",
    "referral links would look like": "referral links would look like",
    "URL Shortener": "URL Shortener",
    "None": "None",
    "See where to find it": "See where to find it",
    "Create short links for existing affiliates": "Create short links for existing affiliates",
    "Default Referral Link": "Default Referral Link",
    "Change the default referral link which appears in the affiliate portal": "Change the default referral link which appears in the affiliate portal",
    "Default Network Link": "Default Network Link",
    "Change the default network link for affiliates to recruit other affiliates": "Change the default network link for affiliates to recruit other affiliates",
    */
    "recurring_plan_daily":'{num, plural, one {day} other {{num} days}}',
    "recurring_plan_weekly":'{num, plural, one {week} other {{num} weeks}}',
    "recurring_plan_monthly":'{num, plural, one {mo} other {{num} mo}}',
    "recurring_plan_yearly":'year',
    "due_in_x":'Due in {x, number} {x, plural, one {day} other {days}}',
    "x members":"{x, number} {x, plural, one {member}, other {members}}"
}
