import React from "react";
function InputGroup(props){
    const {prefix, suffix} = props;
    return <div className="input-group">
        {
            prefix &&             <div className="input-group-prepend">
                <span className="input-group-text">{prefix}</span>
            </div>

        }
        <input className="form-control" {...props} value={props.value === undefined || props.value === null ? '': props.value}/>
        {
            suffix &&
            <div className="input-group-append">
                <span className="input-group-text">{suffix}</span>
            </div>
        }
    </div>
}
export default InputGroup
