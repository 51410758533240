import {ACTIONS} from "./settingsAction";
import * as localStorage from '../../utils/storage';

const defaultSettings = {
  access_token: localStorage.getItem("access_token"),
  marketingIntroCompleted: localStorage.getItem("marketingIntroCompleted"),
  useMultiSiteToken: sessionStorage.getItem('multi_site_access_token') && localStorage.getItem('useMultiSiteToken')
};

export default (state = defaultSettings, action)=>{
  switch (action.type) {
      case ACTIONS.merge:
        return parseJson({...state, ...action.data});
    case ACTIONS.set:
      return parseJson(action.data);
    default:
      return state;
  }
}

function parseJson(state){
  jsonKeys.forEach((i)=>{
    if(state[i] && typeof  state[i] === "string"){
      state[i] = JSON.parse(state[i])
    }
  })
  return state;
}

const jsonKeys = ['custom_leaderboards']
