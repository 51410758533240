const defaultState = []

export default (state = defaultState, action)=>{
    switch (action.type) {
        case "set-components":
            return action.state;
        case "update-components":
            return state.map((item)=>item.id === action.component_id ? {...item, ...action.change} : item)
        default:
            return state;
    }
}
