import React, {useEffect} from 'react';
import {getCurrentQueryObject} from '../../../../utils/func';
import {baseURL} from '../../../../api';
import FakeProgressBar from '../../../../components/FakeProgressBar';

const axios = require('axios');
function RechargeOAuthCallback() {
    useEffect(()=>{
      const {code, myshopify_domain} =  getCurrentQueryObject();
      axios.post(`${baseURL}/rechargepayments/save_keys`, {
        code, myshopify_domain
      }).then((e)=>{
        window.location = `/`
      })
    })
    return (
        <div className={"bg-light min-vh-100"}>
            <div className={"pt-4 container mx-auto d-flex justify-content-center"}>
                <div className="col-md-6 text-center">
                    <p className={"display-4"}>Please wait</p>
                    <FakeProgressBar />
                </div>
            </div>
        </div>
    );

}

export default RechargeOAuthCallback;
